import * as actionTypes from "./actionTypes";
import {HospitalInterface} from "../declarations";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";
import * as actions from "./index";
import axios from "axios";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {routes} from "../../utils/consants/routes";

export const setHospitals = (hospitals: HospitalInterface[]) => {
    return {
        type: actionTypes.HOSPITALS_SET,
        hospitals
    };
};

export const removeHospital = () => {
    return {
        type: actionTypes.HOSPITAL_CLEAR
    };
};

export const updateHospital = (payload: any, history: any) => {
    return (dispatch: any, getState: any) => {
        const config = getAxiosConfigWithToken(getState().auth.token);
        dispatch(actions.showSpinnerModal());

        axios.put(apiEndpoints.API_HOSPITAL_UPDATE, payload, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Hospital/Edit.ts] API_HOSPITAL_UPDATE', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.push(routes.hospitalList)
            })
            .catch((e) => {
                console.error('[Hospital/Edit.ts] API_HOSPITAL_UPDATE', e);
                dispatch(actions.showToast(getErrorMessageFromResponse(e)));
                console.error('[hospital/new.ts] UPDATE_HOSPITAL', e);
            }).finally(() => dispatch(actions.hideSpinnerModal()));
    };
};

export const createHospital = (payload: any, history: any) => {
    return (dispatch: any, getState: any) => {
        const config = getAxiosConfigWithToken(getState().auth.token);
        dispatch(actions.showSpinnerModal());

        return axios.post(apiEndpoints.API_HOSPITAL_NEW, payload, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Hospitals/Create/Create.ts] API_HOSPITAL_NEW', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.push(routes.hospitalList)
            })
            .catch((e) => {
                dispatch(actions.showToast(getErrorMessageFromResponse(e)));
                console.error('[hospital/new.ts] NEW_HOSPITAL', e);
            }).finally(() => dispatch(actions.hideSpinnerModal()));
    }
};
