import {FormInterface} from "../../../declarations";
import {buttonExit} from "../../controllButtons/controllButtons";
import {custom_email, email} from "../../inputFields/inputFields";

const emailForm: FormInterface = {
    form_name: 'Email küldés',
    buttons: [
        buttonExit,
        {
            action: 'send',
            text: 'Küldés',
            position: 'right',
            type: 'primary',
        }
    ],
    form_data: {
        patient_email: {
            ...email,
            label: 'Páciens email címe',
            elementConfig: {
                type: 'text'
            }
        },
        custom_email
    }
};

export default emailForm;
