import React from "react";
import {NavLink} from "react-router-dom";

import classes from "./ControlButtons.module.scss";
import {IonIcon} from "@ionic/react";
import {addCircleOutline, documentTextOutline, eyeOutline, mailOpenOutline, pencilOutline} from "ionicons/icons";
import Guard from "../../../hoc/guard/guard";
import {role} from "../../../utils/consants/auth";
import {useSelector} from "react-redux";
import {isUserHasAccess} from "../../../utils/functions/auth";
import {CERTIFICATE_FORM, COVID_FORM} from "../../../utils/functions/constants";

interface ChartControlButtonsInterface {
    hash: string;
    chooseNewSubFormType: any;
    editPatientFormOnclickHandler: any;
    isPatientFormValid: boolean;
    mainFormType: any;
}

const ChartControlButtons: React.FC<ChartControlButtonsInterface> = (props) => {
    const {
        hash,
        chooseNewSubFormType,
        editPatientFormOnclickHandler,
        isPatientFormValid,
        mainFormType
    } = props;
    const {assignRoles} = useSelector((state: any) => state.user);

    return (
        <>
            {([CERTIFICATE_FORM, COVID_FORM].indexOf(mainFormType) < 0 && isPatientFormValid) && <Guard roles={[role.doctor]}>
                <div className={classes["link"]} onClick={() => chooseNewSubFormType(hash)}>
                    <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                             icon={addCircleOutline}/>
                </div>
            </Guard>}

            {isPatientFormValid && <NavLink to={'/charts/' + hash + '/pdf'}>
                <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                         icon={documentTextOutline}/>
            </NavLink>}

            {isPatientFormValid && <Guard roles={[role.doctor]}>
                <NavLink to={'/charts/' + hash + '/email'}>
                    <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                             icon={mailOpenOutline}/>
                </NavLink>
            </Guard>}

            <div className={classes["link"]}
                 onClick={() => editPatientFormOnclickHandler(hash, isPatientFormValid)}>
                <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                         icon={isUserHasAccess([role.admin], assignRoles) || isPatientFormValid ? eyeOutline : pencilOutline}/>
            </div>
        </>
    )
};

export default ChartControlButtons;
