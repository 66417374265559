import {FormDataInterface} from "../../../../declarations";
import {date_of_birth, email, first_name, last_name, nationality, sex} from "../../../inputFields/inputFields";

const athlete_data: FormDataInterface = {
    first_name,
    last_name,
    date_of_birth,
    nationality,
    sex,
    email
};

export default athlete_data;
