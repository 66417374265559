import {FormDataInterface} from "../../../../declarations";
import {
    patient_doctor_signature,
    payment,
    payment_button,
    privacy,
    privacy_button,
    signature
} from "../../../inputFields/inputFields";

const privacy_payment: FormDataInterface = {
    privacy,
    privacy_button,
    payment,
    payment_button,
    signature,
    patient_doctor_signature,
};

export default privacy_payment;
