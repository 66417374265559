import React, {useEffect} from "react";
import {
    IonButton,
    IonContent,
    IonItem,
    IonNote,
    IonPage,
    IonRow,
    useIonViewWillEnter
} from "@ionic/react";
import {RouteComponentProps} from "react-router-dom";

import loginForm from "../../store/static/forms/login/login";
import logo from "../../assets/images/logo.png";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/UI/Input/Input";
import classes from "./Login.module.scss";
import {setFormArray} from "../../utils/functions/form";
import _ from "lodash";
import {ActiveFormState, AuthState} from "../../store/states";

const Login: React.FC<RouteComponentProps> = () => {
    const {inputHandler, updateActiveForm, activeForm, setFormValidity, resetActiveForm} = ActiveFormState();
    const {doAuth, authError} = AuthState();
    const formElementsArray: any[] = setFormArray(activeForm);

    useIonViewWillEnter(() => {
        updateActiveForm(_.cloneDeep(loginForm));
    });

    const submitHandler = () => {
        doAuth(activeForm.form_data.username.value, activeForm.form_data.password.value);
    };

    useEffect(() => {
        return () => {
            resetActiveForm();
        }
    }, [resetActiveForm]);

    useEffect(() => {
        if (activeForm) {
            setFormValidity(activeForm);
        }
    }, [activeForm, setFormValidity]);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            submitHandler();
        }
    }

    return (
        <IonPage>
            <IonContent className={classes["login"]}>
                <img width="290" className={classes["login__logo"]} src={logo} alt="avatar"/>
                <h1 className={classes["login__title"]}>Medical Chart</h1>

                <form>
                    <div className={classes["login__input"]}>
                        {formElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                inputName={formElement.id}
                                {...formElement.config}
                                changed={(event: any) => inputHandler(event, formElement.id)}
                                onKeyDown={onKeyDown}
                            />
                        ))}
                    </div>

                    {authError && <IonNote color="danger">Bejelentkezési hiba!</IonNote>}

                    <IonRow className={[classes["login__input"], classes["login__input--margin-top"]].join(' ')}>
                        <IonItem lines="none">
                            <IonButton
                                expand="full"
                                size="large"
                                color="success"
                                shape="round"
                                onClick={submitHandler}
                            >
                                Belépés
                            </IonButton>
                        </IonItem>
                    </IonRow>
                </form>

                <Footer/>
            </IonContent>
        </IonPage>
    );
};


export default Login;
