import * as actions from "../../store/actions";
import {AlertInterface} from "../declarations";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";

const Alert = () => {
    const alerts: AlertInterface[] = useSelector((state: any) => state.alert.alerts);

    const dispatch = useDispatch();
    const showSuccessAlert = (message: string) => dispatch(actions.showSuccessAlert(message));
    const showSyncAlert = useCallback(() => dispatch(actions.showSyncAlert()), [dispatch]);
    const hideAlert = (alertIndex: number) => dispatch(actions.hideAlert(alertIndex));

    return {
        showSyncAlert,
        alerts,
        showSuccessAlert,
        hideAlert
    }
};

export default Alert;
