import {PdfButtonControllerInterface, PdfButtonInterface} from "../../declarations";
import {
    CERTIFICATE_FORM,
    COVID_FORM,
    MEDICINE_FORM,
    PATIENT_FORM,
    PATIENT_SHORT_FORM
} from "../../../utils/functions/constants";

const patient_data: PdfButtonInterface = {
    fileName: 'patient_data',
    text: 'Páciens adatainak letöltése',
};

const photos: PdfButtonInterface = {
    fileName: 'photos',
    text: 'Fotók letöltése',
};

const privacy_policy: PdfButtonInterface = {
    fileName: 'privacy_policy',
    text: 'Adatkezelési nyilatkozat letöltése',
};

const insurance_policy: PdfButtonInterface = {
    fileName: 'insurance_policy',
    text: 'Biztosítási feltételek letöltése',
};

const medical_certificate: PdfButtonInterface = {
    fileName: 'medical_certificate',
    text: 'Orvosi igazolás letöltése',
};

const consent_form: PdfButtonInterface = {
    fileName: 'consent_form',
    text: 'Beleegyező nyilatkozat letöltése',
};

const examination: PdfButtonInterface = {
    fileName: 'examination',
    text: 'Vizsgálatok letöltése',
};

const covid_consent: PdfButtonInterface = {
    fileName: 'covid_consent',
    text: 'Covid nyilatkozat letöltése',
};

export const pdfButtons: PdfButtonControllerInterface = {
    [PATIENT_FORM]: [
        patient_data, photos, privacy_policy, insurance_policy,
        medical_certificate, consent_form, examination
    ],
    [PATIENT_SHORT_FORM]: [
        patient_data, photos, privacy_policy, insurance_policy, consent_form
    ],
    [MEDICINE_FORM]: [
        patient_data, photos, privacy_policy, insurance_policy, consent_form
    ],
    [COVID_FORM]: [
        covid_consent
    ],
    [CERTIFICATE_FORM]: [
        patient_data, photos, privacy_policy, medical_certificate
    ]
};