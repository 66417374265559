import * as actionTypes from "./actionTypes";
import * as storageTypes from "../plugins/storageTypes";
import * as actions from "./index";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {clearStorage, getStorage, setStorage} from "../plugins/storage";
import {initAuthFromStorage, login} from "../../utils/functions/auth";
import {isBrowser} from "../../utils/functions/device";

export const authLoginStartStart = () => {
    return {
        type: actionTypes.AUTH_START_LOADING
    };
};

export const authLoadingEnd = () => {
    return {
        type: actionTypes.AUTH_LOADING_END
    };
};

export const authSuccess = (token: string, expirationDate: Date | string) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        expirationDate
    };
};

export const authFail = (error: any) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    return (dispatch: any) => {
        console.log('[actions/auth.ts] logout');
        dispatch(authLoginStartStart());
        dispatch(clearAuth());
        dispatch(actions.clearUser());
        dispatch(actions.clearEvent());
        dispatch(actions.stopAutoSync());
    };
};

export const clearAuth = () => {
    return (dispatch: any) => {
        clearStorage(storageTypes.AUTH).then(() => {
            console.log('[actions/auth.ts] clearAuth done');
            dispatch(removeAuthFromStore());
        }).catch(err => {
            dispatch(authFail(true));
            dispatch(actions.showToast('A tárolt adatok ürítése sikertelen volt. Kérem próbálja meg újra.'));
        });
    }
};

export const removeAuthFromStore = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime: any) => {
    return (dispatch: any) => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const setAuthRedirectPath = (path: any) => {
    return {
        type: actionTypes.AUTH_SET_REDIRECT_PATH,
        path: path
    };
};

export const updateAuth = (token: string, expirationDate: Date) => {
    return (dispatch: any) => {
        const auth = {
            token,
            expirationDate
        };
        dispatch(authSuccess(token, expirationDate));

        return setStorage(storageTypes.AUTH, auth).catch(_ => {
            console.log('[actions/auth.ts] updateAuth stored');
            dispatch(authFail(true));
            dispatch(actions.showToast('Az adatok mentése sikertelen volt. Kérem próbálja meg újra.'));
        });
    };
};

export const doAuth = (username: string, password: string) => {
    return (dispatch: any) => {
        const authData = {
            username,
            password
        };
        let apiPath = apiEndpoints.API_AUTH_DOCTOR_LOGIN;

        dispatch(authLoginStartStart());
        console.log('[actions/auth.ts] authLoginStartStart');

        if (isBrowser) {
            apiPath = apiEndpoints.API_AUTH_ADMIN_LOGIN;
        }

        login(authData, dispatch, apiPath);
    };
};

export const authCheckState = () => {
    return (dispatch: any) => {
        dispatch(authLoginStartStart());
        getStorage(storageTypes.AUTH).then((auth: any) => {
            if (auth !== undefined) {
                if (auth.token) {
                    initAuthFromStorage(dispatch, auth).then(() => {
                        dispatch(actions.authLoadingEnd());
                    });
                } else {
                    dispatch(logout());
                    console.log('[actions/auth.ts] authCheckState logout no token');
                }
            } else {
                console.log('[actions/auth.ts] authCheckState logout');
                dispatch(logout());
            }
        });
    };
};
