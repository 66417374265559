import React, {useEffect, useRef} from 'react';
import SignaturePad from "react-signature-canvas";
import {IonButton} from "@ionic/react";

import classes from './Signature.module.scss';

interface SignatureProps {
    changed: any;
    value?: any;
    disabled: boolean;
}

const Signature: React.FC<SignatureProps> = ({changed, value, disabled}) => {
    let signaturePad: any = useRef();

    useEffect(() => {
        if (signaturePad && signaturePad.current) {
            signaturePad.current.fromDataURL(value, {ratio: 1})

            if (!value) {
                signaturePad.current.clear();
            }
        }
    }, [value, signaturePad])

    const canvasProps = {
        width: 340,
        height: 200,
        className: classes['signature__pad']
    };

    const clearSignaturePad = (event: any) => {
        signaturePad.current.clear();
        event.target.value = '';
        changed(event);
    };

    const signatureEnd = (event: any) => {
        event.target.value = signaturePad.current.toDataURL();
        changed(event);
    };

    return (
        <div className={[classes['signature']].join(' ')}>
            {disabled
                ? <img alt=""
                       src={value}
                       width={canvasProps.width}
                       className={canvasProps.className}/>
                : <>
                    <SignaturePad
                        canvasProps={canvasProps}
                        onEnd={signatureEnd}
                        /*ref={(ref: any) => {
                            signaturePad = ref;
                            if (signaturePad) {
                                signaturePad.fromDataURL(value, {ratio: 1});
                            }
                        }}*/
                        ref={signaturePad}
                    />

                </>}
            {!disabled && <IonButton onClick={clearSignaturePad} color="danger">Törlés</IonButton>}
        </div>
    );
};

export default React.memo(Signature);
