import React, {useCallback} from 'react';
import {IonContent, IonPage, useIonViewDidEnter, useIonViewWillLeave} from "@ionic/react";
import {useHistory} from "react-router";
import _ from "lodash";
import axios from "axios";

import {
    AppendFormWithOptions,
    getEventInputData, onRowDeleteButtonClick, populateDynamicInputGroups,
} from "../../../utils/functions/form";
import {routes} from "../../../utils/consants/routes";
import * as apiEndpoints from "../../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../../utils/functions/http";
import Spinner from "../../../components/Spinner/Spinner";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import form from "../../../store/static/forms/event/event"
import {ActiveFormState, EventState, AuthState, ToastState} from "../../../store/states";

const Edit: React.FC = (props: any) => {
    const {
        updateActiveForm,
        activeForm,
        validateForm,
        doSave,
        resetActiveForm,
        inputHandler,
        addNewRowOfDynamicInput,
        deleteDynamicLastRow
    } = ActiveFormState();
    const {token} = AuthState();
    const {updateEvent} = EventState();
    const {showToast} = ToastState();

    const {match} = props;
    const history = useHistory();

    const getEventID = () => history.location.pathname.split('/').slice(-1).toString();
    let eventID = getEventID();

    const initForm = useCallback(async () => {
        const config = getAxiosConfigWithToken(token);
        const appendedForm = await AppendFormWithOptions({...form}, config);

        if (!appendedForm) {
            return;
        }

        const newForm = _.cloneDeep(appendedForm);
        const payload = {
            id: eventID
        };
        console.log('[Events/Edit/Edit.ts] initForm');

        axios.post(apiEndpoints.API_EVENT_SHOW, payload, config)
            .then((response: any) => {
                if (response.data.success !== true) {
                    console.error('[Events/Edit/Edit.ts] API_EVENT_SHOW', response);
                    showToast(getErrorMessageFromResponse(response));
                    return;
                }

                const {payload} = response.data;
                newForm.form_data['name'].value = payload.name;
                newForm.form_data['name_en'].value = payload.name_en || payload.name;
                newForm.form_data['start'].value = payload.start;
                newForm.form_data['end'].value = payload.end;
                newForm.form_data['password'].value = payload.password;
                newForm.form_data['city'].value = payload.city;
                newForm.form_data['address'].value = payload.address;
                newForm.form_data['emails'].value = payload.emails;
                newForm.form_data['sports'].value = payload.sports.split(',');
                newForm.form_data['hospital'].value = payload.hospitals && payload.hospitals.map((hospital: any) => hospital.value);
                newForm.form_data['accreditation'].value = payload.accreditation && payload.accreditation.map((accreditation: any) => accreditation.value);
                newForm.form_data['logo'].base64Data = payload.logo ? process.env.REACT_APP_API_BASE_URL + payload.logo.path : '';
                newForm.form_data['logo'].value = payload.logo ? payload.logo.path.split(payload.logo.path[0]).slice(-1) : '';
                newForm.form_data['covid_text'].value = payload.covid_text;
                newForm.form_data['insurance_text'].value = payload.insurance_text;
                newForm.form_data['insurance_text_en'].value = payload.insurance_text_en;

                const dynamicInputGroups = {
                    'place_of_care_dynamic': payload.place_of_care.length || 1
                };

                newForm.form_data = populateDynamicInputGroups(newForm.form_data, dynamicInputGroups, 1);

                payload.place_of_care.forEach((place: any, index: number) => {
                    if (newForm.form_data['place_of_care_' + (index + 1)]) {
                        newForm.form_data['place_of_care_' + (index + 1)].value = place.name;
                    }
                })

                validateForm(newForm);
                updateActiveForm(newForm);
            })
            .catch((e) => {
                console.error('[Events/Edit/Edit.ts] API_EVENT_SHOW', e);
                showToast('Az adatok lekérése a szerverről sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.');
            });


    }, [eventID, showToast, token, updateActiveForm, validateForm]);

    useIonViewDidEnter(() => {
        eventID = getEventID();
        initForm();
    }, [match.params.id]);

    useIonViewWillLeave(() => resetActiveForm());

    const save = () => {
        doSave(() => {
            const payload = getEventInputData(activeForm.form_data);
            payload.event.id = parseInt(eventID);
            updateEvent(payload, history);
        });
    };

    const exit = () => {
        props.history.push(routes.eventList);
    };

    const inputButtonHandlers = [
        {
            inputName: 'place_of_care_button',
            handler: addNewRowOfDynamicInput
        },
        {
            inputName: 'delete',
            handler: (e: any, inputName: string) => onRowDeleteButtonClick(e, inputName, deleteDynamicLastRow)
        }
    ];

    const drawForm = () => {
        if (!activeForm) {
            return <Spinner/>;
        }

        return <FormBuilder
            controlButtonActions={{
                save,
                exit,
            }}
            inputHandler={inputHandler}
            activeForm={activeForm}
            activeFormIsLocked={false}
            inputButtonHandlers={inputButtonHandlers}
            {...props}
        />;
    };

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>Esemény szerkesztése</h1>

                {drawForm()}
            </IonContent>
        </IonPage>
    );
};

export default Edit;
