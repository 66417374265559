import {EventInterface} from "../../declarations";

const contents = [
    {
        id: 'privacy_button',
        title: 'Adatkezelési nyilatkozat / Privacy statement',
        text: () => (`
            <h2>INFORMÁCIÓK:</h2>
            <p>ADATKEZELŐ NEVE: Semmelweis Egészségügyi Kft</p>
            <p>KÉPVISELŐJE: Kovács Róbert ügyvezető</p>
            <p>HONLAPJA: www.semmelweiskft.hu</p>
            <p>AZ ADATKEZELÉS CÉLJA: egészségügyi ellátás biztosítása</p>
            <p>AZ ADATKEZELÉS JOGALAPJA: Az érintett hozzájárulása</p>
            <p>A SZEMÉLYES ADATOK CÍMZETTJEI: Adatkezelő és szerződődött adatfeldolgozói</p>

            <h2>TÁJÉKOZTATÁS AZ ÉRINTETT JOGAIRÓL:</h2>
            <p>Önnek, mint érintett személynek joga van kérelmezni az adatkezelőtől az Önre vonatkozó
                személyes
                adatokhoz való hozzáférést, azok helyesbítését, törlését vagy kezelésének korlátozását, és
                tiltakozhat az ilyen személyes adatok kezelése ellen, valamint a joga van az
                adathordozhatósághoz.<br/>
                Joga van a hozzájárulása bármely időpontban történő visszavonásához, amely nem érinti a
                visszavonás előtt a hozzájárulás alapján végrehajtott adatkezelés jogszerűségét.<br/>
                Joga van a felügyeleti hatósághoz (Nemzeti Adatvédelmi és Információszabadság Hatóság)
                panaszt
                benyújtani.</p>

            <p>Az adatszolgáltatás előfeltétele az orvosi ellátásnak, de, a személyes adatok megadására nem
                vagyok köteles. Az adatszolgáltatás elmaradásának lehetséges következménye: az Adatkezelő
                nem
                tud teljeskörű szolgáltatást nyújtani.</p>

            <p>A fenti információkat és tájékoztatást tudomásul vettem, fent megadott személyes adataim
                fentiekben megjelölt célú kezeléséhez önkéntesen, minden külső befolyás nélkül
                beleegyezésemet
                adom.</p>

            <p>Tudomásul veszem, hogy az ellátás igénybevételével elfogadom a Szolgáltató Általános
                Szerződési
                Feltételeit (ÁSZF), ami a www.semmelweiskft.hu oldalon megismerhető.</p>

            <hr/>

            <h2>INFORMATION:</h2>
            <p>DATA CONTROLLER’S NAME: Semmelweis Egészségügyi Kft</p>
            <p>DATA CONTROLLER’S REPRESENTATIVE: Kovács Róbert manager</p>
            <p>DATA CONTROLLER’S WEBSITE: www.semmelweiskft.hu</p>
            <p>DATA MANAGEMENT PURPOSE: provision of health care</p>
            <p>DATA MANAGEMENT LEGAL BASIS: Consent of the person concerned</p>
            <p>RECIPIENTS OF THE PERSONAL DATA: Data processors of data controller and contracted </p>

            <h2>INFORMATION ON THE RIGHTS OF THE PERSON CONCERNED:</h2>
            <p>You, as a person concerned, have the right to request the data controller to access, rectify, delete or
                restrict the processing of your personal data and to object to the processing of such personal data, as
                well as the right to data portability.<br/>
                You have the right to withdraw your consent at any time, which shall not affect the lawfulness of
                processing based on consent before its withdrawal.<br/>
                You have the right to lodge a complaint with the supervisory authority (National Authority for Data
                Protection and Freedom of Information). </p>

            <p>The provision of patient information is a prerequisite for medical care, however, I am not obliged to provide personal
                data. A possible consequence of the failure to provide data is that the Data Controller is unable to
                provide a full service. </p>

            <p>I have taken note of the above information and briefing and I give my consent to the processing of my
                personal data provided above for the purposes indicated above voluntarily, without any outside
                influence. </p>

            <p>I acknowledge that by using the service I accept the Service Provider's General Terms and Conditions
                (GTC), which can be found on the website www.semmelweiskft.hu.
            </p>
        `)
    },
    {
        id: 'payment_button',
        title: 'Biztosítási feltételek / Insurance policy',
        text: (event: EventInterface) => event.insurance_text + '<hr/>' + event.insurance_text_en
    },
    {
        id: 'covid_button',
        title: 'Covid ügyfélnyilatkozat / Covid statement',
        text: (event: EventInterface) => event.covid_text
    }
];

export default contents;
