import axios from "../../axios";
import {API_MAIL} from "../consants/apiEndpoints";
import {getErrorMessageFromResponse} from "./http";

export const sendEmail = (payload: any, config: any, successCallback: any, actions: any) => {
    axios.post(API_MAIL, payload, config)
        .then((response) => {
            if (response.data.success !== true) {
                console.error('[Email.tsx] API_MAIL', response);
                actions.showToast('Az email küldés sikertelen volt. Hiba üzenet: ' + getErrorMessageFromResponse(response));
                return;
            }

            successCallback();
        })
        .catch(() => actions.showToast('Az email küldés sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'))
        .finally(() => {
            actions.hideSpinnerModal();
            if (actions.setSpinnerMessage) {
                actions.setSpinnerMessage('');
            }
        });
};