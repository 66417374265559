import React from 'react';
import {IonCol, IonGrid, IonRow} from '@ionic/react';
import {RouteComponentProps} from "react-router";

import Input from '../../components/UI/Input/Input';
import {setFormArray} from "../../utils/functions/form";
import {ButtonHandlerInterface} from "../../store/declarations";
import ControlButtons from "../UI/ControlButtons/ControlButtons";
import classes from "./FormBuilder.module.scss";

interface PropsInterface extends RouteComponentProps {
    inputButtonHandlers?: ButtonHandlerInterface[];
    controlButtonActions?: any;
    activeForm: any;
    inputHandler: any;
    activeFormIsLocked: boolean;
}

const FormBuilder: React.FC<PropsInterface> = (props) => {
    const {
        inputButtonHandlers,
        controlButtonActions,
        activeForm,
        inputHandler,
        activeFormIsLocked
    } = props;

    const formElementsArray: any[] = setFormArray(activeForm);
    const formElementsToDisplay = formElementsArray.filter(elem => !elem.config?.elementConfig.row || formElementsArray.find(button => button.id === elem.config?.elementConfig.button)?.config?.elementConfig.displayedRows.indexOf(elem.config?.elementConfig.row) >= 0);

    return (
        <>
            {activeForm &&
            <IonGrid className="ion-padding">
                <IonRow>
                    <IonCol size="12">
                        <div>
                            <h1 className={classes['heading-1']}>{activeForm.form_name}</h1>
                            {activeForm.info ?
                                <h5><strong dangerouslySetInnerHTML={{__html: activeForm.info}}/></h5> : null}
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-align-items-end">
                    {formElementsToDisplay.map(formElement => {
                        return !formElement.config?.elementConfig.hide && (
                            <React.Fragment key={formElement.id}>
                                <IonCol
                                    className={[classes['ion-col'], 'ion-align-self-end'].join(' ')}
                                    size={formElement.config.elementConfig.colSize || '12'}
                                >
                                    <Input
                                        inputName={formElement.id}
                                        {...formElement.config}
                                        changed={(event: any) => inputHandler(event, formElement.id)}
                                        buttonHandlers={inputButtonHandlers}
                                        isActiveFormLocked={activeFormIsLocked}
                                    />
                                </IonCol>
                            </React.Fragment>
                        )
                    })}
                </IonRow>

                <IonRow>
                    <IonCol size="12">
                        <ControlButtons buttonActions={controlButtonActions}/>
                    </IonCol>
                </IonRow>
            </IonGrid>
            }
        </>
    );
};

export default React.memo(FormBuilder);
