import React, {useCallback, useState} from 'react';
import {RouteComponentProps} from "react-router";
import {IonContent, IonPage, useIonViewDidEnter, useIonViewWillLeave} from "@ionic/react";
import _ from "lodash";

import Header from "../../../components/Header/Header";

import emailForm from "../../../store/static/forms/email/email";
import {ChartInterface, FormInterface, InputFieldInterface} from "../../../store/declarations";
import Spinner from "../../../components/Spinner/Spinner";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import {getChartByHash, getPdfTypesForCurrentChartType} from "../../../utils/functions/chart";
import {getAxiosConfigWithToken} from "../../../utils/functions/http";
import {getFieldValue, initFormValues, isEmail} from "../../../utils/functions/form";
import {collectEmailAddresses, validateEmails} from "../../../utils/functions/common";
import {routes} from "../../../utils/consants/routes";
import {
    ActiveFormState,
    EventState,
    ChartState,
    AuthState,
    ToastState,
    AlertState,
    SpinnerModalState
} from "../../../store/states";
import {useFilesystem} from "@ionic/react-hooks/filesystem";
import {COVID_FORM} from "../../../utils/functions/constants";
import {sendEmail} from "../../../utils/functions/email";

interface EmailProps extends RouteComponentProps<{
    chartHash: string;
}> {
}

const Email: React.FC<EmailProps> = (props) => {
    const {updateActiveForm, activeForm, resetActiveForm, inputHandler} = ActiveFormState();
    const {charts, isChartsLoading} = ChartState();
    const {event} = EventState();
    const {token} = AuthState();
    const {showToast} = ToastState();
    const {showSuccessAlert} = AlertState();
    const {showSpinnerModal, hideSpinnerModal} = SpinnerModalState();

    const {match, history} = props;
    const getCurrentChart = useCallback(() => getChartByHash(charts, match.params.chartHash), [charts, match]);
    const {readFile} = useFilesystem();

    const [homeRouteSuffix, setHomeRouteSuffix] = useState(routes.homeDefault);

    const setValues = useCallback((chart: ChartInterface, newForm: FormInterface) => {
        let admissionToEmailAddress = '';
        let patientEmailAddress = '';

        if (chart.forms.length >= 1) {
            patientEmailAddress = getFieldValue('email', chart.forms[0].input_fields);
            patientEmailAddress = setEmailValue(patientEmailAddress);
        }

        if (chart.forms.length > 1) {
            admissionToEmailAddress = getFieldValue('admission_to', chart.forms[chart.forms.length - 1].input_fields);
            admissionToEmailAddress = setEmailValue(admissionToEmailAddress);
        }

        const values: InputFieldInterface[] = [
            {
                name: 'admission_to_email',
                value: admissionToEmailAddress
            },
            {
                name: 'organizer_email',
                value: event.emails
            },
            {
                name: 'patient_email',
                value: patientEmailAddress
            }
        ];

        return initFormValues(newForm, values, event, readFile);
    }, [event, readFile]);

    const initForm = useCallback(async () => {
        const chart = getCurrentChart();

        if (chart !== null && emailForm) {
            const newForm = _.cloneDeep(emailForm);

            if (chart.forms[0].type === COVID_FORM) {
                setHomeRouteSuffix(routes.homeCovid);
            }
            else {
                setHomeRouteSuffix(routes.homeDefault);
            }

            await setValues(chart, newForm);
            updateActiveForm(newForm);
        }
    }, [getCurrentChart, setValues, updateActiveForm]);

    useIonViewDidEnter(async () => {
        if (!isChartsLoading) {
            initForm();
        }
    }, [match.params]);

    useIonViewWillLeave(() => resetActiveForm());

    const onSuccessfulEmailSend = (chartMainFormType: string) => {
        showSuccessAlert('Az email sikeresen elküldve.');
        const routeSuffix = chartMainFormType === COVID_FORM ? routes.homeCovid : routes.homeDefault;
        history.push(routes.userHome + routeSuffix);
    };

    const sendMail = (emailList: string) => {
        const chart = getCurrentChart();
        const pdfTypesForCurrentChartType = getPdfTypesForCurrentChartType(chart!.forms[0].type);
        const payload = {
            emails: emailList,
            hash: match.params.chartHash,
            types: pdfTypesForCurrentChartType
        };
        const config = getAxiosConfigWithToken(token);

        showSpinnerModal();

        sendEmail(payload, config, () => onSuccessfulEmailSend(chart!.forms[0].type), {showToast, hideSpinnerModal})
    };

    const setEmailValue = (value: string) => isEmail(value) ? value : '';

    const controlButtonActions = {
        'send': async () => {
            const emailAddresses = collectEmailAddresses(activeForm);

            if (!validateEmails(emailAddresses)) {
                showToast('Hibás email cím formátum')
                return;
            }

            sendMail(emailAddresses);
        },
        'exit': () => {
            history.push(routes.userHome + homeRouteSuffix)
        },
    };

    const chartForm = () => {
        if (!activeForm) {
            return <Spinner/>;
        }

        return <FormBuilder
            controlButtonActions={controlButtonActions}
            inputHandler={inputHandler}
            activeForm={activeForm}
            activeFormIsLocked={false}
            {...props}
        />;
    };

    return (
        <IonPage>
            <IonContent>
                <Header/>

                {chartForm()}
            </IonContent>
        </IonPage>
    );
};

export default Email;
