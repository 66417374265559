import React, {useCallback, useEffect, useRef, useState} from 'react';
import classes from './Translate.module.scss';
import axios from "../../../axios";
import * as apiEndpoints from "../../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken} from "../../../utils/functions/http";
import {useSelector} from "react-redux";

interface PropsInterface {
    textToTranslate: string;
}

const Translate: React.FC<PropsInterface> = (props) => {
    const {textToTranslate} = props;
    const recentlyUpdated = useRef<{ value: boolean, timeout: any }>({value: false, timeout: null});
    const [translatedText, updateTranslatedText] = useState('-');
    const token: string = useSelector((state: any) => state.auth.token);
    const config = getAxiosConfigWithToken(token);
    const isMountedRef = useRef(false);

    const translateText = useCallback(() => {
        updateTranslatedText('-');
        if (textToTranslate && isMountedRef.current) {
            recentlyUpdated.current.value = true;

            axios
                .post(apiEndpoints.API_TRANSLATE, {text: textToTranslate}, config)
                .then((response: any) => {
                    if (response.data.success === true) {
                        if (isMountedRef.current) {
                            updateTranslatedText(response.data.payload);
                        }
                        setTimeout(() => {
                            recentlyUpdated.current.value = false;
                        }, 100);
                    }
                });
        }
    }, [config, textToTranslate]);

    useEffect(() => {
        isMountedRef.current = true;
        const current = recentlyUpdated.current;
        if (!current.value) {
            clearTimeout(current.timeout);
            current.timeout = (setTimeout(() => {
                translateText();
            }, 300));
        }

        return () => {
            clearTimeout(current.timeout);
            isMountedRef.current = false;
        };
    }, [recentlyUpdated, translateText]);

    return (
        <p className={classes.translateText}><i>Translation:</i> {translatedText} {recentlyUpdated.current.value}</p>
    );
};

export default React.memo(Translate);
