import {defaultFormConfig} from "../forms/helper/helper";

interface dynamicInputGroupsInterface {
    [key: string]: any;
}

export const dynamicGroups: dynamicInputGroupsInterface = {
    'drugs_dynamic': {
        isDynamic: true,
        templates: {
            row: {
                inputRowGenerator: (numOfCurrentInput: number) => {
                    const drugInputBody = {
                        label: numOfCurrentInput + '. gyógyszer / Drug no' + numOfCurrentInput,
                        helper: '&nbsp;',
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            row: 'drugs_row_' + numOfCurrentInput,
                            button: 'drugs_button',
                            colSize: '3'
                        },
                        validation: {
                            required: false
                        },
                        ...defaultFormConfig,
                        valid: true
                    };
                    const ingredientInputBody = {
                        label: numOfCurrentInput + '. hatóanyag / Active ingredients no' + numOfCurrentInput,
                        helper: '&nbsp;',
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            row: 'drugs_row_' + numOfCurrentInput,
                            button: 'drugs_button',
                            colSize: '3'
                        },
                        validation: {
                            required: false
                        },
                        ...defaultFormConfig,
                        valid: true
                    };
                    const doseBody = {
                        label: numOfCurrentInput + '. dózis / Dose no' + numOfCurrentInput,
                        helper: '&nbsp;',
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            row: 'drugs_row_' + numOfCurrentInput,
                            button: 'drugs_button',
                            colSize: '2'
                        },
                        validation: {
                            required: false,
                        },
                        ...defaultFormConfig,
                        valid: true
                    };
                    const wadaBody = {
                        label: 'WADA lista / WADA list',
                        elementType: 'checkbox',
                        elementConfig: {
                            row: 'drugs_row_' + numOfCurrentInput,
                            button: 'drugs_button',
                            colSize: '3'
                        },
                        validation: {
                            required: false,
                        },
                        ...defaultFormConfig,
                        valid: true
                    }

                    return [
                        [['drugs_' + numOfCurrentInput], drugInputBody],
                        [['ingredients_' + numOfCurrentInput], ingredientInputBody],
                        [['dose_' + numOfCurrentInput], doseBody],
                        [['drugs_wada_' + numOfCurrentInput], wadaBody]
                    ];
                }
            }
        }
    },
    'infusions_dynamic': {
        isDynamic: true,
        templates: {
            row: {
                inputRowGenerator: (numOfCurrentInput: number) => {
                    const infusionInputBody = {
                        label: numOfCurrentInput + '. infúzió / Infusion no' + numOfCurrentInput,
                        helper: '&nbsp;',
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            row: 'infusion_row_' + numOfCurrentInput,
                            button: 'infusion_button',
                            colSize: '3'
                        },
                        validation: {
                            required: false
                        },
                        ...defaultFormConfig,
                        valid: true
                    };
                    const ingredientInputBody = {
                        label: numOfCurrentInput + '. hatóanyag / Active ingredients no' + numOfCurrentInput,
                        helper: '&nbsp;',
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            row: 'infusion_row_' + numOfCurrentInput,
                            button: 'infusion_button',
                            colSize: '3'
                        },
                        validation: {
                            required: false
                        },
                        ...defaultFormConfig,
                        valid: true
                    };
                    const doseBody = {
                        label: numOfCurrentInput + '. dózis / Dose no' + numOfCurrentInput,
                        helper: '&nbsp;',
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            row: 'infusion_row_' + numOfCurrentInput,
                            button: 'infusion_button',
                            colSize: '2'
                        },
                        validation: {
                            required: false,
                        },
                        ...defaultFormConfig,
                        valid: true
                    };
                    const wadaBody = {
                        label: 'WADA lista / WADA list',
                        elementType: 'checkbox',
                        elementConfig: {
                            row: 'infusion_row_' + numOfCurrentInput,
                            button: 'infusion_button',
                            colSize: '3'
                        },
                        validation: {
                            required: false,
                        },
                        ...defaultFormConfig,
                        valid: true
                    }

                    return [
                        [['infusion_' + numOfCurrentInput], infusionInputBody],
                        [['infusion_ingredients_' + numOfCurrentInput], ingredientInputBody],
                        [['infusion_dose_' + numOfCurrentInput], doseBody],
                        [['infusion_wada_' + numOfCurrentInput], wadaBody]
                    ];
                }
            }
        }
    },
    'place_of_care_dynamic': {
        isDynamic: true,
        templates: {
            row: {
                inputRowGenerator: (numOfCurrentInput: number) => {
                    const placeOfCareBody = {
                        label: 'Ellátás helyszíne ' + numOfCurrentInput,
                        helper: '&nbsp;',
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            row: 'place_of_care_row_' + numOfCurrentInput,
                            button: 'place_of_care_button',
                            colSize: '10'
                        },
                        validation: {
                            required: false
                        },
                        ...defaultFormConfig,
                        valid: true
                    };

                    return [
                        [['place_of_care_' + numOfCurrentInput], placeOfCareBody],
                    ];
                }
            }
        }
    }
};

export const dynamicInputGroupIdRelations = [
    {
        name: 'drugs_',
        inputGroupId: 'drugs_dynamic'
    },
    {
        name: 'infusion_',
        inputGroupId: 'infusions_dynamic'
    },
    {
        name: 'place_of_care_',
        inputGroupId: 'place_of_care_dynamic'
    }
]