import {
    ChartFormInterface,
    ChartInterface,
    EmptyChartParamsInterface,
    FormInterface,
    InputFieldInterface, PdfButtonInterface
} from "../../store/declarations";
import {CERTIFICATE_FORM, COVID_FORM, PATIENT_FORM, PATIENT_SHORT_FORM} from "./constants";
import {ChartEditRouteParams} from "../../store/declarations/route";
import {loadSavedPhoto} from "./photo";
import {isBase64Image} from "./common";
import {FormTypes} from "../../store/types";
import {uuid} from "uuidv4";
import {FIRST_FORM_PAGE_INDEX} from "../consants/charts";
import {pdfButtons} from "../../store/static/pdfButtons/pdfButtons";

export const getChartByHash = (charts: ChartInterface[], chartHash: any) => {
    if (!charts) {
        return null;
    }

    return charts.find((chart: ChartInterface) => {
        return chart.hash === chartHash;
    }) ?? null;
};

export const getFirstChartFormByType = (chart: ChartInterface, formType: FormTypes): ChartFormInterface | null => {
    if (!chart || !chart.forms) {
        return null;
    }

    return chart.forms.filter((form) => form.type === formType)[0] ?? null;
};

export const getAllChartFormByType = (chart: ChartInterface, formType: FormTypes): ChartFormInterface[] => {
    if (!chart || chart.forms.length === 0) {
        return [];
    }

    return chart.forms.filter((form) => form.type === formType);
};

export const getPatientFormHash = (charts: ChartInterface[], chartHash: string): number | any => {
    const chart = getChartByHash(charts, chartHash);
    let formHash = null;

    if (chart) {
        chart.forms.forEach((form) => {
            if (form.type === PATIENT_FORM) {
                formHash = form.hash;
            }
        })
    }

    return formHash;
};

export const getMainFormByChartHash = (charts: ChartInterface[], chartHash: string): number | any => {
    const chart = getChartByHash(charts, chartHash);
    let mainForm = null;

    if (chart) {
        chart.forms.forEach((form) => {
            if ([PATIENT_FORM, PATIENT_SHORT_FORM, COVID_FORM, CERTIFICATE_FORM].indexOf(form.type) >= 0) {
                mainForm = form;
            }
        })
    }

    return mainForm;
};

export const getChartFormById = (chart: ChartInterface, formHash: any): ChartFormInterface | null => {
    return chart.forms.filter((form) => form.hash === formHash)[0] ?? null;
};

export const navigationToChardEditPage = (history: any, routeParams: ChartEditRouteParams) => history.push(`/charts/${routeParams.chartHash}/${routeParams.formType}/${routeParams.formHash}/${routeParams.formPageIndex}`);

export const isExistChartsInState = (getState: any) => getState().chart.charts !== null && getState().chart.charts !== undefined;

export const initEmptyChart = (emptyChartParams: EmptyChartParamsInterface, formType: FormTypes): ChartInterface => {
    const {eventId, chartHash, userId, formHash} = emptyChartParams;
    return {
        event_id: eventId,
        hash: chartHash,
        send_mail_to: '',
        last_sync_date: 0,
        created_date: new Date().getTime(),
        forms: [initEmptyForm(formType, userId, formHash)]
    }
};

export const initEmptyForm = (formType: FormTypes, userId: number, formHash: string): ChartFormInterface => {
    return {
        hash: formHash,
        input_fields: [],
        type: formType,
        created_date: new Date().getTime(),
        user_id: userId,
        locked: false
    }
};

export const fillUpChartFormFields = (chartForm: ChartFormInterface, form: FormInterface) => {
    for (let inputIdentifier in form.form_data) {
        if (form.form_data.hasOwnProperty(inputIdentifier)) {
            const value = form.form_data[inputIdentifier].value;
            let foundField = false;

            if (chartForm.input_fields) {
                chartForm.input_fields.forEach((field: InputFieldInterface) => {
                    if (field.name === inputIdentifier) {
                        foundField = true;
                        field.value = value;
                        field.saved = true;
                    }
                });
            }

            if (!foundField) {
                chartForm.input_fields.push({
                    name: inputIdentifier,
                    value: value,
                    saved: true
                });
            }
        }
    }
};

export const getSyncCharts = (getState: any): ChartInterface[] => {
    if (isStateEmptyWithCharts(getState)) {
        return [];
    }

    return getState().chart.charts
        .filter((chart: ChartInterface) => chart.event_id === getState().event.id)
        .filter((chart: ChartInterface) => chart.last_sync_date === 0);
};

export const isFormsValid = (chart: ChartInterface) => {
    for (let form of chart.forms) {
        if (!form.locked) {
            return false;
        }
    }

    return true;
};

export const isStateEmptyWithCharts = (getState: any) => typeof getState().chart === "undefined" && typeof getState().chart.charts === "undefined";

export const loadPhotosFromFilesystem = async (charts: ChartInterface[], readFile: any) => {
    for (const chart of charts) {
        for (let form of chart.forms) {
            for (let field of form.input_fields) {
                if (['accreditation_front', 'accreditation_back', 'other_photo', 'patient_photo'].indexOf(field.name) > -1 && field.value) {
                    if (!isBase64Image(field.value)) {
                        field.value = await loadSavedPhoto(field.value, readFile);
                    }
                }
            }
        }
    }
};

export const setCharInputFieldsSavedProperty = (charts: ChartInterface[]) => {
    for (let chart of charts) {
        for (let form of chart.forms) {
            for (let field of form.input_fields) {
                field.saved = true;
            }
        }
    }
};

export const isAllFormsOfChartEmpty = (chart: ChartInterface) => {
    for (const form of chart.forms) {
        if (form.input_fields.length > 0) {
            return false;
        }
    }
    return true;
};

export const initNewChart = (formType: FormTypes, createNewChart: any) => {
    const chartHash = uuid();
    const formHash = uuid();
    const routeParams: ChartEditRouteParams = {
        chartHash,
        formType,
        formHash,
        formPageIndex: FIRST_FORM_PAGE_INDEX
    };
    createNewChart(chartHash, formHash, formType);

    return routeParams;
};

export const getPdfTypesForCurrentChartType = (chartType: string) => pdfButtons[chartType].map((pdfOption: PdfButtonInterface) => pdfOption.fileName)

export const getChartListTitle = (chartListType: string): string => {
    if (chartListType === 'covid') {
        return 'Covid chartok';
    }

    return 'Általános chartok';
}
