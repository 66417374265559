import {FormDataInterface} from "../../../../declarations";
import {accreditation_back, accreditation_front, other_photo, patient_photo} from "../../../inputFields/inputFields";

const photos: FormDataInterface = {
    accreditation_front,
    accreditation_back,
    patient_photo,
    other_photo
};

export default photos;
