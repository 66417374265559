import {FormDataInterface} from "../../../../declarations";
import {
    absence_in_days, affected_system,
    cause_of_injury,
    cause_of_symptoms, complaints, location_of_injury_head, location_of_injury_lower,
    location_of_injury_upper,
    main_symptoms,
    time_of_injury, type_of_injury
} from "../../../inputFields/inputFields";

const complaints_injury: FormDataInterface = {
    complaints,
    time_of_injury,
    location_of_injury_head,
    location_of_injury_upper,
    location_of_injury_lower,
    type_of_injury,
    cause_of_injury,
    absence_in_days,
    affected_system,
    main_symptoms,
    cause_of_symptoms,
};

export default complaints_injury;
