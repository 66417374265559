import patient, {patientShort} from "./patient";
import examination, {medicine} from "./examination";
import covid from "./covid";
import certificate from "./certificate";

let form: any[any] = [];

form['patient'] = patient;
form['examination'] = examination;
form['patientShort'] = patientShort;
form['medicine'] = medicine;
form['covid'] = covid;
form['certificate'] = certificate;

export default form;
