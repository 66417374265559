import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions";

const Auth = () => {
    const isAuthenticated = useSelector((state: any) => state.auth.token !== null);
    const authError = useSelector((state: any) => state.auth.error);
    const authRedirectPath = useSelector((state: any) => state.auth.authRedirectPath);
    const token: string = useSelector((state: any) => state.auth.token);

    const dispatch = useDispatch();
    const doAuth = (username: string, password: string) => dispatch(actions.doAuth(username, password));

    return {
        isAuthenticated,
        authError,
        authRedirectPath,
        token,
        doAuth
    }
};

export default Auth;
