import React from "react";
import classes from "./Image.module.scss";

interface LogoProps {
  src: string;
  alt: string;
}

const image: React.FC<LogoProps> = props => (
  <div className={classes.Logo}>
    <img src={props.src} alt={props.alt} />
  </div>
);

export default image;
