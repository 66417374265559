import React from "react";
import {Editor} from '@tinymce/tinymce-react';
import './TextEditor.scss';

interface TextEditorInterface {
    value: any;
    changed: any;
}

const TextEditor: React.FC<TextEditorInterface> = ({value, changed}) => {
    const setEditorState = (value: any) => {
        const event = {target: {value: value}};
        changed(event);
    };

    return (
        <Editor
            apiKey={'v1q3yo509aotial0bqzx1z53f8hc7h996w9b4gs7uf56pnit'}
            value={value}
            init={{
                height: 500,
                menubar: false,
                block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;',
                quickbars_selection_toolbar: 'bold italic | quicklink h1 h2',
                plugins: 'preview paste searchreplace autolink directionality visualblocks visualchars link charmap hr lists textpattern help quickbars',
                toolbar:
                    'undo redo | formatselect | bold italic underline backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | hr | removeformat | help'
            }}
            onEditorChange={setEditorState}
        />
    );
};

export default React.memo(TextEditor);
