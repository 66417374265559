import {ChartInterface} from "../../store/declarations";

export const isSyncChartEmpty = (syncCharts: ChartInterface[]) => !syncCharts.length || syncCharts.length === 0;

export const setSyncDate = (charts: ChartInterface[], lastSyncDate: number) => {
    for (const chart of charts) {
        chart.last_sync_date = lastSyncDate;
    }
};

export const clearEmptyFields = (charts: ChartInterface []) => {
    for (const chart of charts) {
        for (const form of chart.forms) {
            const clearedInputFields = [];
            for (const inputField of form.input_fields) {
                if (inputField.value !== '') {
                    clearedInputFields.push(inputField);
                }
            }
            form.input_fields = clearedInputFields;
        }
    }
};
