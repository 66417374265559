import * as actions from "../../store/actions";
import {useDispatch} from "react-redux";

const SpinnerModal = () => {
    const dispatch = useDispatch();
    const hideSpinnerModal = () => dispatch(actions.hideSpinnerModal());
    const showSpinnerModal = () => dispatch(actions.showSpinnerModal());
    const setSpinnerMessage = (message: string) => dispatch(actions.setSpinnerMessage(message));

    return {
        hideSpinnerModal,
        showSpinnerModal,
        setSpinnerMessage
    }
};

export default SpinnerModal;
