import React from "react";
import {useSelector} from "react-redux";
import {isUserHasAccess} from "../../utils/functions/auth";

type GuardProps = {
    roles: string[]
};

const Guard: React.FunctionComponent<GuardProps> = (props) => {
    const {assignRoles} = useSelector((state: any) => state.user);
    const {roles} = props;

    return <>{roles.length === 0 || isUserHasAccess(roles, assignRoles) ? props.children : null}</>
};

export default Guard;
