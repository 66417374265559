import React, {useCallback, useState} from 'react';
import {IonContent, IonPage, IonSearchbar, useIonViewDidEnter} from "@ionic/react";
import {RouteComponentProps} from "react-router";

import ChartsList from "../../../components/ChartList/ChartList";
import Alert from "../../../components/Alert/Alert";
import Header from "../../../components/Header/Header";

import {getFieldValue} from "../../../utils/functions/form";
import {ChartInterface, InputFieldInterface} from "../../../store/declarations";
import {isNeedFilter} from "../../../utils/functions/filter";
import {ChartState, AlertState, SpinnerModalState} from "../../../store/states";

interface ListProps extends RouteComponentProps<{
    chartListType: string;
}> {
    showSearchBar: boolean;
}

const List: React.FC<ListProps> = (props) => {
    const {setCharts, charts, synChartsFromLocalToServer} = ChartState();
    const {hideAlert, alerts, showSyncAlert} = AlertState();
    const {hideSpinnerModal, showSpinnerModal} = SpinnerModalState();

    const [searchQuery, updateSearchQuery] = useState();
    const {match} = props;

    const initSyncAlert = useCallback(() => {
        let isShowSyncAlert = false;

        if (charts.length) {
            for (let chart of charts) {
                if (chart.last_sync_date === 0) {
                    isShowSyncAlert = true;
                    break;
                }
            }
        }

        if (isShowSyncAlert) {
            showSyncAlert();
        }
    }, [charts, showSyncAlert]);

    useIonViewDidEnter(() => {
        initSyncAlert();
    }, [match.params]);

    const syncButtonHandler = () => {
        console.log('[List.tsx] syncButtonHandler');
        const lastSyncDate = new Date().getTime();
        showSpinnerModal();

        synChartsFromLocalToServer(lastSyncDate).then(() => {
            hideSpinnerModal();
        }).catch(() => {
            hideSpinnerModal();
        });
    };

    const searchBarChangeHandler = (event: any) => {
        const query = event.detail.value!;

        updateSearchQuery(query);
        doFilter(query);
    };

    const doFilter = (query: string) => {
        if (isNeedFilter(query, charts)) {
            const newFilteredChartList = charts.map((chart: ChartInterface) => {
                const patientForm = chart.forms[0];

                if (patientForm) {
                    chart.filtered = shouldFilter(patientForm.input_fields, query);
                }

                return chart;
            });

            setCharts(newFilteredChartList);
        }
    };

    const shouldFilter = (fields: InputFieldInterface[], query: string): boolean => {
        const inputFields = ['first_name', 'last_name'];
        return !inputFields.filter((fieldName: string) => {
            return getFieldValue(fieldName, fields).toLowerCase().indexOf(query.toLowerCase().trim()) > -1;
        }).length;
    };

    const alertTemplate = alerts.map((alert, key) => {
        if (alert.position === 'sticky') {
            alert.callback = syncButtonHandler;
        }

        return <Alert
            key={key}
            {...alert}
            closeHandler={() => hideAlert(key)}
        />;
    });

    const searchBar = () => {
        if (props.showSearchBar) {
            return <IonSearchbar
                value={searchQuery}
                onIonChange={searchBarChangeHandler}
                showCancelButton="focus"
                animated
                placeholder="Keresés"/>;
        }

        return null;
    };

    return (
        <IonPage>
            <IonContent>
                {searchBar()}
                <Header/>

                <div className="ion-padding">
                    {alertTemplate}
                    <ChartsList {...props}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default List;
