import React, {useRef} from 'react';
import {IonButton, IonIcon, IonImg} from "@ionic/react";
import {cameraOutline, image, imageOutline} from "ionicons/icons";

import classes from './Photo.module.scss';
import {CameraResultType, CameraSource} from "@capacitor/core";
import {imageToBase64, SavePicture} from "../../../utils/functions/photo";
import {FileInterface, FormInterface} from "../../../store/declarations";
import _ from "lodash";
import {useCamera} from "@ionic/react-hooks/camera";
import {useFilesystem} from "@ionic/react-hooks/filesystem";
import {ActiveFormState} from "../../../store/states";

interface PhotoProps {
    elementType: string;
    base64Data: string;
    inputName?: string;
    disabled: boolean;
}

const Photo: React.FC<PhotoProps> = ({elementType, base64Data, inputName, disabled}) => {
    const {activeForm, updateActiveForm, setFormValidity} = ActiveFormState();
    const {writeFile} = useFilesystem();

    const src = base64Data || image;
    const imgPreview = <IonImg slot="start" className={classes['photo__img']} src={src} alt=""/>;

    const fileInput = useRef(null);
    const {getPhoto} = useCamera();

    const takePhoto = async (inputName: any, inputFile: File | null = null) => {
        if (inputFile) {
            const base64: string = await imageToBase64(inputFile);
            updateFormByPhoto(inputName, {fileName: inputFile.name, base64Data: base64});
            return;
        }

        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100
        });
        const savedFileImage = await SavePicture(cameraPhoto, writeFile);
        updateFormByPhoto(inputName, savedFileImage);
    };

    const updateFormByPhoto = (inputName: string, savedFileImage: FileInterface) => {
        const updatedForm: FormInterface = _.cloneDeep(activeForm);
        const updatedFormElement = {...updatedForm.form_data[inputName]};

        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.value = savedFileImage.fileName;
        updatedFormElement.base64Data = savedFileImage.base64Data;
        updatedForm.form_data[inputName] = updatedFormElement;

        setFormValidity(updatedForm);
        updateActiveForm(updatedForm);
    };

    const removePhoto = (inputName: any) => {
        const updatedForm: FormInterface = _.cloneDeep(activeForm);
        const updatedFormElement = {...updatedForm.form_data[inputName]};

        updatedFormElement.touched = false;
        updatedFormElement.valid = false;
        updatedFormElement.value = '';
        updatedFormElement.base64Data = '';
        updatedForm.form_data[inputName] = updatedFormElement;

        setFormValidity(updatedForm);
        updateActiveForm(updatedForm);
    };

    const imageInputButton = () => {
        if (elementType === 'picture') {
            return (
                <>
                    {!disabled && base64Data && <IonButton color="danger" className="ion-padding-end"
                                                                     onClick={() => removePhoto(inputName)}>Törlés</IonButton>}
                    <IonButton slot="end" color="success" onClick={() => takePhoto(inputName)}>
                        <IonIcon slot="icon-only" icon={cameraOutline}/>
                    </IonButton>

                </>
            );
        }

        return (
            <>
                <input
                    accept="image/*"
                    hidden
                    ref={fileInput}
                    type="file"
                    onChange={e =>
                        takePhoto(inputName,
                            (e.nativeEvent.target as HTMLInputElement).files?.item(0) ||
                            ({} as File)
                        )
                    }
                />

                <IonButton slot="end" color="success" onClick={() => {
                    // @ts-ignore
                    fileInput.current.click()
                }}>
                    <IonIcon slot="icon-only" icon={imageOutline}/>
                </IonButton>
            </>
        )
    };

    return (
        <>
            <div className={classes['photo']}>
                {!disabled && imageInputButton()}
            </div>

            {imgPreview}
        </>
    );
};

export default Photo;
