import {FormDataInterface, FormInterface} from "../../../declarations";
import {buttonExit, buttonSave} from "../../controllButtons/controllButtons";
import {hospital_emails, name} from "../../inputFields/inputFields";

const hospital: FormDataInterface = {
    name: {
        ...name,
        label: 'Klinika neve',
    },
    value: {
        ...hospital_emails
    }
};

const forms: FormInterface = {
    form_name: "",
    form_data: hospital,
    buttons: [
        buttonExit,
        buttonSave
    ]
};

export default forms;
