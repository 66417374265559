import * as actionTypes from "./actionTypes";
import {FormInterface} from "../declarations";
import _ from "lodash";
import {
    changeFormElement, deleteInputValues,
    handleDynamicInputs, isEndDateGraterThenBeginDate, populateDynamicInputGroups,
    setValidity
} from "../../utils/functions/form";
import {keys, validateEmails} from "../../utils/functions/common";
import * as actions from "./index";

export const updateActiveForm = (form: FormInterface | undefined) => {
    return {
        type: actionTypes.ACTIVE_FORM_UPDATE,
        form
    }
};

export const updateActiveFormState = (form: FormInterface | undefined, isValid: boolean) => {
    return {
        type: actionTypes.ACTIVE_FORM_UPDATE_WHOLE,
        form,
        isValid
    }
};

export const resetActiveForm = () => {
    return {
        type: actionTypes.ACTIVE_FORM_RESET,
    }
};

export const updateActiveFormIsValid = (isValid: boolean) => {
    return {
        type: actionTypes.ACTIVE_FORM_UPDATE_IS_VALID,
        isValid
    }
};

export const updateActiveFormIsLocked = (isLocked: boolean) => {
    return {
        type: actionTypes.ACTIVE_FORM_UPDATE_IS_LOCKED,
        isLocked
    }
};

export const touchFormData = (form: FormInterface) => {
    const updatedForm: FormInterface = _.cloneDeep(form);

    for (let inputIdentifier in updatedForm.form_data) {
        if (updatedForm.form_data.hasOwnProperty(inputIdentifier)) {
            updatedForm.form_data[inputIdentifier].touched = true;
        }
    }

    return {
        type: actionTypes.ACTIVE_FORM_UPDATE,
        form: updatedForm
    }
};

export const setFormValidity = (form: FormInterface) => {
    return (dispatch: any) => {
        let formIsValid = true;

        for (let inputIdentifier in form.form_data) {
            if (form.form_data.hasOwnProperty(inputIdentifier)) {
                formIsValid = form.form_data[inputIdentifier].valid && formIsValid;
            }
        }

        dispatch(updateActiveFormIsValid(formIsValid));
    };
};

export const validateForm = (form: FormInterface) => {
    return (dispatch: any) => {
        console.log('validateForm');
        for (let inputName of keys(form.form_data)) {
            form.form_data[inputName].valid = setValidity(form.form_data[inputName].value, form.form_data[inputName].validation);
        }
        dispatch(setFormValidity(form));
    }
};

export const inputHandler = (event: any, inputIdentifier: string) => {
    return (dispatch: any, getState: any) => {
        console.log('inputHandler');
        const updatedForm: FormInterface = _.cloneDeep(getState().activeForm.form);

        if (!updatedForm) {
            return;
        }

        const updatedFormElement = updatedForm.form_data[inputIdentifier];
        let value = event.target.value;

        if (!updatedFormElement) {
            return;
        }

        if (updatedFormElement.elementType === 'checkbox') {
            value = event.target.checked ? '1' : '';
        }

        if (JSON.stringify(updatedFormElement.value) === JSON.stringify(value)) {
            return;
        }

        changeFormElement(updatedFormElement, value);
        handleDynamicInputs(inputIdentifier, getState().activeForm.form);

        let formIsValid = true;

        for (let inputIdentifier in updatedForm.form_data) {
            if (updatedForm.form_data.hasOwnProperty(inputIdentifier)) {
                formIsValid = updatedForm.form_data[inputIdentifier].valid && formIsValid;
            }
        }

        dispatch(updateActiveFormState(updatedForm, formIsValid));
    };
};

export const doSave = (callback: any) => {
    return (dispatch: any, getState: any) => {
        console.log('[actions/activeForm.ts] do save');
        const {form, isValid} = getState().activeForm;
        if (!isValid) {
            dispatch(touchFormData(form));
            return;
        }

        if (!validateEmails(form.form_data)) {
            dispatch(actions.showToast('Hibás email cím formátum'));
            return;
        }

        if (isEndDateGraterThenBeginDate(form.form_data)) {
            dispatch(actions.showToast('A kezdő dátum nem lehet nagyobb a befejező dátumnál!'));
            return;
        }
        callback();
    };
};

export const hideDynamicRow = (deleteButtonIdentifier: any) => {
    return (dispatch: any, getState: any) => {
        const newForm = _.cloneDeep(getState().activeForm.form);
        const deleteButtonConfig = newForm.form_data[deleteButtonIdentifier].elementConfig;
        const visibleRowsHandlerConfig = newForm.form_data[deleteButtonConfig.button] ? newForm.form_data[deleteButtonConfig.button].elementConfig : deleteButtonConfig;
        const currentRowIndex = visibleRowsHandlerConfig.displayedRows.indexOf(deleteButtonConfig.row);

        visibleRowsHandlerConfig.displayedRows.splice(currentRowIndex, 1);
        visibleRowsHandlerConfig.hide = false;
        deleteInputValues(deleteButtonConfig, newForm);

        dispatch(updateActiveForm(newForm));
    };
};

export const displayNextElementOfDynamicInputs = (event: any, statusInputElementID: string) => {
    return (dispatch: any, getState: any) => {
        const newForm = _.cloneDeep(getState().activeForm.form);
        const displayedRowsIDs = newForm.form_data[statusInputElementID].elementConfig.displayedRows;

        let hiddenRows: any = [];
        Object.keys(newForm.form_data).forEach(input => {
            const currentConfig = newForm.form_data[input].elementConfig;
            if (currentConfig.button === statusInputElementID && displayedRowsIDs.indexOf(currentConfig.row) < 0 && hiddenRows.indexOf(currentConfig.row) < 0) {
                hiddenRows.push(currentConfig.row);
            }
        });

        if (hiddenRows[0]) {
            displayedRowsIDs.push(hiddenRows[0]);
        }

        if (hiddenRows.length < 2 && !newForm.form_data[statusInputElementID].elementConfig.showIfAllRowsDisplayed) {
            newForm.form_data[statusInputElementID].elementConfig.hide = true;
        }

        dispatch(updateActiveForm(newForm));
    };
};

export const addNewRowOfDynamicInput = (event: any, statusInputElementID: string) => {
    return (dispatch: any, getState: any) => {
        const newForm = _.cloneDeep(getState().activeForm.form);
        const {displayedRows, inputGroupTemplate} = newForm.form_data[statusInputElementID].elementConfig;

        const newInputRowTypes = {[inputGroupTemplate]: 1};
        const lastRowId = displayedRows[displayedRows.length - 1];
        newForm.form_data = populateDynamicInputGroups(newForm.form_data, newInputRowTypes, parseInt(lastRowId.split('_')[lastRowId.split('_').length - 1]) + 1);

        dispatch(updateActiveForm(newForm));
    };
};

export const deleteDynamicLastRow = (statusInputElementID: string) => {
    return (dispatch: any, getState: any) => {
        const newForm = _.cloneDeep(getState().activeForm.form);
        const dynamicElementControllerId = newForm.form_data[statusInputElementID].elementConfig.button;
        const rowToDelete = newForm.form_data[dynamicElementControllerId].elementConfig.displayedRows.pop();
        Object.keys(newForm.form_data).forEach(input => {
            if (newForm.form_data[input].elementConfig.row === rowToDelete && newForm.form_data[input].elementType !== 'button') {
                delete newForm.form_data[input];
            }
        })

        dispatch(updateActiveForm(newForm));
    };
};
