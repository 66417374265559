export const doFilter = (query: string, items: any[], updateFilterItems: any) => {
    if (isNeedFilter(query, items)) {
        const newFilteredEventList = items.map((item: any) => {
            item.filtered = shouldFilter(item.name, query);
            return item;
        });

        updateFilterItems(newFilteredEventList);
    }
};

export const isNeedFilter = (query: string, items: any[]): boolean => {
    return typeof query !== "undefined" && !!items.length;
};

export const shouldFilter = (field: string, query: string): boolean => {
    const inputFields = ['name'];
    return !inputFields.filter(_ => {
        return field.toLowerCase().indexOf(query.toLowerCase().trim()) > -1;
    }).length;
};
