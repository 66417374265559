import {useDispatch, useSelector} from "react-redux";
import * as actions from "../actions";
import {HospitalInterface} from "../declarations";

const Hospital = () => {
    const hospitals: HospitalInterface[] = useSelector((state: any) => state.hospitals);

    const dispatch = useDispatch();
    const createHospital = (payload: any, history: any) => dispatch(actions.createHospital(payload, history))
    const updateHospital = (payload: any, history: any) => dispatch(actions.updateHospital(payload, history));
    const setHospital = (hospitals: HospitalInterface[]) => dispatch(actions.setHospitals(hospitals));

    return {
        hospitals,
        createHospital,
        updateHospital,
        setHospital
    }
};

export default Hospital;
