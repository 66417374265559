export const AUTH_START_LOADING = "AUTH_START_LOADING";
export const AUTH_LOADING_END = "AUTH_LOADING_END";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_REDIRECT_PATH = "AUTH_SET_REDIRECT_PATH";

export const SYNC_START = "SYNC_START";
export const SYNC_END = "SYNC_END";
export const SYNC_SUCCESS = "SYNC_SUCCESS";
export const SYNC_FAIL = "SYNC_FAIL";

export const TOAST_SHOW = "TOAST_SHOW";
export const TOAST_HIDE = "TOAST_HIDE";

export const SPINNER_MODAL_SHOW = "SPINNER_MODAL_SHOW";
export const SPINNER_MODAL_HIDE = "SPINNER_MODAL_HIDE";
export const SPINNER_SET_MESSAGE = "SPINNER_SET_MESSAGE";

export const USER_SET = "USER_SET";
export const USER_CLEAR = "USER_CLEAR";

export const SET_EVENT = "SET_EVENT";
export const SET_EVENTS = "SET_EVENTS";
export const EVENT_CLEAR = "EVENT_CLEAR";

export const HOSPITALS_SET = "HOSPITALS_SET";
export const HOSPITAL_CLEAR = "HOSPITAL_CLEAR";

export const ALERT_SHOW_SUCCESS = 'ALERT_SHOW_SUCCESS';
export const ALERT_HIDE_SUCCESS = 'ALERT_HIDE_SUCCESS';
export const ALERT_SHOW_DANGER = 'ALERT_SHOW_DANGER';
export const ALERT_HIDE_DANGER = 'ALERT_HIDE_DANGER';
export const ALERT_SHOW_SYNC = 'ALERT_SHOW_SYNC';
export const ALERT_HIDE_SYNC = 'ALERT_HIDE_SYNC';
export const ALERT_HIDE = 'ALERT_HIDE';

export const CHART_START = 'CHART_START';
export const CHART_END = 'CHART_END';
export const CHARTS_LOAD_ERROR = 'CHARTS_LOAD_ERROR';
export const CHARTS_SET = 'CHARTS_SET';

export const ACTIVE_FORM_UPDATE = 'ACTIVE_FORM_UPDATE';
export const ACTIVE_FORM_UPDATE_IS_VALID = 'ACTIVE_FORM_UPDATE_IS_VALID';
export const ACTIVE_FORM_UPDATE_IS_LOCKED = 'ACTIVE_FORM_UPDATE_IS_LOCKED';
export const ACTIVE_FORM_RESET = 'ACTIVE_FORM_RESET';
export const ACTIVE_FORM_UPDATE_WHOLE = 'ACTIVE_FORM_UPDATE_WHOLE';
