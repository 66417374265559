import {FormDataInterface, FormInterface} from "../../../declarations";
import {password, username} from "../../inputFields/inputFields";

const login: FormDataInterface = {
    username,
    password
};

const forms: FormInterface = {
    form_name: "Bejelentkezési űrlap",
    form_data: login,
    buttons: []
};

export default forms;
