import React from "react";
import {IonSpinner} from "@ionic/react";

const spinner = (props: any) => {
    return (
        <>
            <div className="ion-padding ion-text-center"><IonSpinner color="primary"/></div>
            <div className="ion-text-center">{props.children}</div>
        </>
    );
};

export default spinner;
