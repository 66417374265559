import {defaultFormConfig} from "../forms/helper/helper";
import {FormConfigInterface} from "../../declarations";

export const first_name: FormConfigInterface = {
    label: 'Vezetéknév / Last name',
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const last_name: FormConfigInterface = {
    label: 'Keresztnév / First name',
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const date_of_birth: FormConfigInterface = {
    label: 'Születési dátum / Date of birth',
    elementType: 'date',
    elementConfig: {
        minDateType: 'past',
        maxDateType: 'present',
    },
    validation: {
        required: true
    },
    ...defaultFormConfig,
};

export const nationality: FormConfigInterface = {
    label: 'Ország / Country of origin',
    elementType: 'select',
    elementConfig: {
        multiple: false,
        options: [
            {
                "displayName": "Afghanistan",
                "optionValue": "Afghanistan"
            },
            {
                "displayName": "Åland Islands",
                "optionValue": "Åland Islands"
            },
            {
                "displayName": "Albania",
                "optionValue": "Albania"
            },
            {
                "displayName": "Algeria",
                "optionValue": "Algeria"
            },
            {
                "displayName": "American Samoa",
                "optionValue": "American Samoa"
            },
            {
                "displayName": "AndorrA",
                "optionValue": "AndorrA"
            },
            {
                "displayName": "Angola",
                "optionValue": "Angola"
            },
            {
                "displayName": "Anguilla",
                "optionValue": "Anguilla"
            },
            {
                "displayName": "Antarctica",
                "optionValue": "Antarctica"
            },
            {
                "displayName": "Antigua and Barbuda",
                "optionValue": "Antigua and Barbuda"
            },
            {
                "displayName": "Argentina",
                "optionValue": "Argentina"
            },
            {
                "displayName": "Armenia",
                "optionValue": "Armenia"
            },
            {
                "displayName": "Aruba",
                "optionValue": "Aruba"
            },
            {
                "displayName": "Australia",
                "optionValue": "Australia"
            },
            {
                "displayName": "Austria",
                "optionValue": "Austria"
            },
            {
                "displayName": "Azerbaijan",
                "optionValue": "Azerbaijan"
            },
            {
                "displayName": "Bahamas",
                "optionValue": "Bahamas"
            },
            {
                "displayName": "Bahrain",
                "optionValue": "Bahrain"
            },
            {
                "displayName": "Bangladesh",
                "optionValue": "Bangladesh"
            },
            {
                "displayName": "Barbados",
                "optionValue": "Barbados"
            },
            {
                "displayName": "Belarus",
                "optionValue": "Belarus"
            },
            {
                "displayName": "Belgium",
                "optionValue": "Belgium"
            },
            {
                "displayName": "Belize",
                "optionValue": "Belize"
            },
            {
                "displayName": "Benin",
                "optionValue": "Benin"
            },
            {
                "displayName": "Bermuda",
                "optionValue": "Bermuda"
            },
            {
                "displayName": "Bhutan",
                "optionValue": "Bhutan"
            },
            {
                "displayName": "Bolivia",
                "optionValue": "Bolivia"
            },
            {
                "displayName": "Bosnia and Herzegovina",
                "optionValue": "Bosnia and Herzegovina"
            },
            {
                "displayName": "Botswana",
                "optionValue": "Botswana"
            },
            {
                "displayName": "Bouvet Island",
                "optionValue": "Bouvet Island"
            },
            {
                "displayName": "Brazil",
                "optionValue": "Brazil"
            },
            {
                "displayName": "British Indian Ocean Territory",
                "optionValue": "British Indian Ocean Territory"
            },
            {
                "displayName": "Brunei Darussalam",
                "optionValue": "Brunei Darussalam"
            },
            {
                "displayName": "Bulgaria",
                "optionValue": "Bulgaria"
            },
            {
                "displayName": "Burkina Faso",
                "optionValue": "Burkina Faso"
            },
            {
                "displayName": "Burundi",
                "optionValue": "Burundi"
            },
            {
                "displayName": "Cambodia",
                "optionValue": "Cambodia"
            },
            {
                "displayName": "Cameroon",
                "optionValue": "Cameroon"
            },
            {
                "displayName": "Canada",
                "optionValue": "Canada"
            },
            {
                "displayName": "Cape Verde",
                "optionValue": "Cape Verde"
            },
            {
                "displayName": "Cayman Islands",
                "optionValue": "Cayman Islands"
            },
            {
                "displayName": "Central African Republic",
                "optionValue": "Central African Republic"
            },
            {
                "displayName": "Chad",
                "optionValue": "Chad"
            },
            {
                "displayName": "Chile",
                "optionValue": "Chile"
            },
            {
                "displayName": "China",
                "optionValue": "China"
            },
            {
                "displayName": "Christmas Island",
                "optionValue": "Christmas Island"
            },
            {
                "displayName": "Cocos (Keeling) Islands",
                "optionValue": "Cocos (Keeling) Islands"
            },
            {
                "displayName": "Colombia",
                "optionValue": "Colombia"
            },
            {
                "displayName": "Comoros",
                "optionValue": "Comoros"
            },
            {
                "displayName": "Congo",
                "optionValue": "Congo"
            },
            {
                "displayName": "Congo, The Democratic Republic of the",
                "optionValue": "Congo, The Democratic Republic of the"
            },
            {
                "displayName": "Cook Islands",
                "optionValue": "Cook Islands"
            },
            {
                "displayName": "Costa Rica",
                "optionValue": "Costa Rica"
            },
            {
                "displayName": "Cote D\"Ivoire",
                "optionValue": "Cote D\"Ivoire"
            },
            {
                "displayName": "Croatia",
                "optionValue": "Croatia"
            },
            {
                "displayName": "Cuba",
                "optionValue": "Cuba"
            },
            {
                "displayName": "Cyprus",
                "optionValue": "Cyprus"
            },
            {
                "displayName": "Czech Republic",
                "optionValue": "Czech Republic"
            },
            {
                "displayName": "Denmark",
                "optionValue": "Denmark"
            },
            {
                "displayName": "Djibouti",
                "optionValue": "Djibouti"
            },
            {
                "displayName": "Dominica",
                "optionValue": "Dominica"
            },
            {
                "displayName": "Dominican Republic",
                "optionValue": "Dominican Republic"
            },
            {
                "displayName": "Ecuador",
                "optionValue": "Ecuador"
            },
            {
                "displayName": "Egypt",
                "optionValue": "Egypt"
            },
            {
                "displayName": "El Salvador",
                "optionValue": "El Salvador"
            },
            {
                "displayName": "Equatorial Guinea",
                "optionValue": "Equatorial Guinea"
            },
            {
                "displayName": "Eritrea",
                "optionValue": "Eritrea"
            },
            {
                "displayName": "Estonia",
                "optionValue": "Estonia"
            },
            {
                "displayName": "Ethiopia",
                "optionValue": "Ethiopia"
            },
            {
                "displayName": "Falkland Islands (Malvinas)",
                "optionValue": "Falkland Islands (Malvinas)"
            },
            {
                "displayName": "Faroe Islands",
                "optionValue": "Faroe Islands"
            },
            {
                "displayName": "Fiji",
                "optionValue": "Fiji"
            },
            {
                "displayName": "Finland",
                "optionValue": "Finland"
            },
            {
                "displayName": "France",
                "optionValue": "France"
            },
            {
                "displayName": "French Guiana",
                "optionValue": "French Guiana"
            },
            {
                "displayName": "French Polynesia",
                "optionValue": "French Polynesia"
            },
            {
                "displayName": "French Southern Territories",
                "optionValue": "French Southern Territories"
            },
            {
                "displayName": "Gabon",
                "optionValue": "Gabon"
            },
            {
                "displayName": "Gambia",
                "optionValue": "Gambia"
            },
            {
                "displayName": "Georgia",
                "optionValue": "Georgia"
            },
            {
                "displayName": "Germany",
                "optionValue": "Germany"
            },
            {
                "displayName": "Ghana",
                "optionValue": "Ghana"
            },
            {
                "displayName": "Gibraltar",
                "optionValue": "Gibraltar"
            },
            {
                "displayName": "Greece",
                "optionValue": "Greece"
            },
            {
                "displayName": "Greenland",
                "optionValue": "Greenland"
            },
            {
                "displayName": "Grenada",
                "optionValue": "Grenada"
            },
            {
                "displayName": "Guadeloupe",
                "optionValue": "Guadeloupe"
            },
            {
                "displayName": "Guam",
                "optionValue": "Guam"
            },
            {
                "displayName": "Guatemala",
                "optionValue": "Guatemala"
            },
            {
                "displayName": "Guernsey",
                "optionValue": "Guernsey"
            },
            {
                "displayName": "Guinea",
                "optionValue": "Guinea"
            },
            {
                "displayName": "Guinea-Bissau",
                "optionValue": "Guinea-Bissau"
            },
            {
                "displayName": "Guyana",
                "optionValue": "Guyana"
            },
            {
                "displayName": "Haiti",
                "optionValue": "Haiti"
            },
            {
                "displayName": "Heard Island and Mcdonald Islands",
                "optionValue": "Heard Island and Mcdonald Islands"
            },
            {
                "displayName": "Holy See (Vatican City State)",
                "optionValue": "Holy See (Vatican City State)"
            },
            {
                "displayName": "Honduras",
                "optionValue": "Honduras"
            },
            {
                "displayName": "Hong Kong",
                "optionValue": "Hong Kong"
            },
            {
                "displayName": "Hungary",
                "optionValue": "Hungary"
            },
            {
                "displayName": "Iceland",
                "optionValue": "Iceland"
            },
            {
                "displayName": "India",
                "optionValue": "India"
            },
            {
                "displayName": "Indonesia",
                "optionValue": "Indonesia"
            },
            {
                "displayName": "Iran, Islamic Republic Of",
                "optionValue": "Iran, Islamic Republic Of"
            },
            {
                "displayName": "Iraq",
                "optionValue": "Iraq"
            },
            {
                "displayName": "Ireland",
                "optionValue": "Ireland"
            },
            {
                "displayName": "Isle of Man",
                "optionValue": "Isle of Man"
            },
            {
                "displayName": "Israel",
                "optionValue": "Israel"
            },
            {
                "displayName": "Italy",
                "optionValue": "Italy"
            },
            {
                "displayName": "Jamaica",
                "optionValue": "Jamaica"
            },
            {
                "displayName": "Japan",
                "optionValue": "Japan"
            },
            {
                "displayName": "Jersey",
                "optionValue": "Jersey"
            },
            {
                "displayName": "Jordan",
                "optionValue": "Jordan"
            },
            {
                "displayName": "Kazakhstan",
                "optionValue": "Kazakhstan"
            },
            {
                "displayName": "Kenya",
                "optionValue": "Kenya"
            },
            {
                "displayName": "Kiribati",
                "optionValue": "Kiribati"
            },
            {
                "displayName": "Korea, Democratic People\"S Republic of",
                "optionValue": "Korea, Democratic People\"S Republic of"
            },
            {
                "displayName": "Korea, Republic of",
                "optionValue": "Korea, Republic of"
            },
            {
                "displayName": "Kuwait",
                "optionValue": "Kuwait"
            },
            {
                "displayName": "Kyrgyzstan",
                "optionValue": "Kyrgyzstan"
            },
            {
                "displayName": "Lao People\"S Democratic Republic",
                "optionValue": "Lao People\"S Democratic Republic"
            },
            {
                "displayName": "Latvia",
                "optionValue": "Latvia"
            },
            {
                "displayName": "Lebanon",
                "optionValue": "Lebanon"
            },
            {
                "displayName": "Lesotho",
                "optionValue": "Lesotho"
            },
            {
                "displayName": "Liberia",
                "optionValue": "Liberia"
            },
            {
                "displayName": "Libyan Arab Jamahiriya",
                "optionValue": "Libyan Arab Jamahiriya"
            },
            {
                "displayName": "Liechtenstein",
                "optionValue": "Liechtenstein"
            },
            {
                "displayName": "Lithuania",
                "optionValue": "Lithuania"
            },
            {
                "displayName": "Luxembourg",
                "optionValue": "Luxembourg"
            },
            {
                "displayName": "Macao",
                "optionValue": "Macao"
            },
            {
                "displayName": "Macedonia, The Former Yugoslav Republic of",
                "optionValue": "Macedonia, The Former Yugoslav Republic of"
            },
            {
                "displayName": "Madagascar",
                "optionValue": "Madagascar"
            },
            {
                "displayName": "Malawi",
                "optionValue": "Malawi"
            },
            {
                "displayName": "Malaysia",
                "optionValue": "Malaysia"
            },
            {
                "displayName": "Maldives",
                "optionValue": "Maldives"
            },
            {
                "displayName": "Mali",
                "optionValue": "Mali"
            },
            {
                "displayName": "Malta",
                "optionValue": "Malta"
            },
            {
                "displayName": "Marshall Islands",
                "optionValue": "Marshall Islands"
            },
            {
                "displayName": "Martinique",
                "optionValue": "Martinique"
            },
            {
                "displayName": "Mauritania",
                "optionValue": "Mauritania"
            },
            {
                "displayName": "Mauritius",
                "optionValue": "Mauritius"
            },
            {
                "displayName": "Mayotte",
                "optionValue": "Mayotte"
            },
            {
                "displayName": "Mexico",
                "optionValue": "Mexico"
            },
            {
                "displayName": "Micronesia, Federated States of",
                "optionValue": "Micronesia, Federated States of"
            },
            {
                "displayName": "Moldova, Republic of",
                "optionValue": "Moldova, Republic of"
            },
            {
                "displayName": "Monaco",
                "optionValue": "Monaco"
            },
            {
                "displayName": "Mongolia",
                "optionValue": "Mongolia"
            },
            {
                "displayName": "Montserrat",
                "optionValue": "Montserrat"
            },
            {
                "displayName": "Morocco",
                "optionValue": "Morocco"
            },
            {
                "displayName": "Mozambique",
                "optionValue": "Mozambique"
            },
            {
                "displayName": "Myanmar",
                "optionValue": "Myanmar"
            },
            {
                "displayName": "Namibia",
                "optionValue": "Namibia"
            },
            {
                "displayName": "Nauru",
                "optionValue": "Nauru"
            },
            {
                "displayName": "Nepal",
                "optionValue": "Nepal"
            },
            {
                "displayName": "Netherlands",
                "optionValue": "Netherlands"
            },
            {
                "displayName": "Netherlands Antilles",
                "optionValue": "Netherlands Antilles"
            },
            {
                "displayName": "New Caledonia",
                "optionValue": "New Caledonia"
            },
            {
                "displayName": "New Zealand",
                "optionValue": "New Zealand"
            },
            {
                "displayName": "Nicaragua",
                "optionValue": "Nicaragua"
            },
            {
                "displayName": "Niger",
                "optionValue": "Niger"
            },
            {
                "displayName": "Nigeria",
                "optionValue": "Nigeria"
            },
            {
                "displayName": "Niue",
                "optionValue": "Niue"
            },
            {
                "displayName": "Norfolk Island",
                "optionValue": "Norfolk Island"
            },
            {
                "displayName": "Northern Mariana Islands",
                "optionValue": "Northern Mariana Islands"
            },
            {
                "displayName": "Norway",
                "optionValue": "Norway"
            },
            {
                "displayName": "Oman",
                "optionValue": "Oman"
            },
            {
                "displayName": "Pakistan",
                "optionValue": "Pakistan"
            },
            {
                "displayName": "Palau",
                "optionValue": "Palau"
            },
            {
                "displayName": "Palestinian Territory, Occupied",
                "optionValue": "Palestinian Territory, Occupied"
            },
            {
                "displayName": "Panama",
                "optionValue": "Panama"
            },
            {
                "displayName": "Papua New Guinea",
                "optionValue": "Papua New Guinea"
            },
            {
                "displayName": "Paraguay",
                "optionValue": "Paraguay"
            },
            {
                "displayName": "Peru",
                "optionValue": "Peru"
            },
            {
                "displayName": "Philippines",
                "optionValue": "Philippines"
            },
            {
                "displayName": "Pitcairn",
                "optionValue": "Pitcairn"
            },
            {
                "displayName": "Poland",
                "optionValue": "Poland"
            },
            {
                "displayName": "Portugal",
                "optionValue": "Portugal"
            },
            {
                "displayName": "Puerto Rico",
                "optionValue": "Puerto Rico"
            },
            {
                "displayName": "Qatar",
                "optionValue": "Qatar"
            },
            {
                "displayName": "Reunion",
                "optionValue": "Reunion"
            },
            {
                "displayName": "Romania",
                "optionValue": "Romania"
            },
            {
                "displayName": "Russian Federation",
                "optionValue": "Russian Federation"
            },
            {
                "displayName": "RWANDA",
                "optionValue": "RWANDA"
            },
            {
                "displayName": "Saint Helena",
                "optionValue": "Saint Helena"
            },
            {
                "displayName": "Saint Kitts and Nevis",
                "optionValue": "Saint Kitts and Nevis"
            },
            {
                "displayName": "Saint Lucia",
                "optionValue": "Saint Lucia"
            },
            {
                "displayName": "Saint Pierre and Miquelon",
                "optionValue": "Saint Pierre and Miquelon"
            },
            {
                "displayName": "Saint Vincent and the Grenadines",
                "optionValue": "Saint Vincent and the Grenadines"
            },
            {
                "displayName": "Samoa",
                "optionValue": "Samoa"
            },
            {
                "displayName": "San Marino",
                "optionValue": "San Marino"
            },
            {
                "displayName": "Sao Tome and Principe",
                "optionValue": "Sao Tome and Principe"
            },
            {
                "displayName": "Saudi Arabia",
                "optionValue": "Saudi Arabia"
            },
            {
                "displayName": "Senegal",
                "optionValue": "Senegal"
            },
            {
                "displayName": "Serbia and Montenegro",
                "optionValue": "Serbia and Montenegro"
            },
            {
                "displayName": "Seychelles",
                "optionValue": "Seychelles"
            },
            {
                "displayName": "Sierra Leone",
                "optionValue": "Sierra Leone"
            },
            {
                "displayName": "Singapore",
                "optionValue": "Singapore"
            },
            {
                "displayName": "Slovakia",
                "optionValue": "Slovakia"
            },
            {
                "displayName": "Slovenia",
                "optionValue": "Slovenia"
            },
            {
                "displayName": "Solomon Islands",
                "optionValue": "Solomon Islands"
            },
            {
                "displayName": "Somalia",
                "optionValue": "Somalia"
            },
            {
                "displayName": "South Africa",
                "optionValue": "South Africa"
            },
            {
                "displayName": "South Georgia and the South Sandwich Islands",
                "optionValue": "South Georgia and the South Sandwich Islands"
            },
            {
                "displayName": "Spain",
                "optionValue": "Spain"
            },
            {
                "displayName": "Sri Lanka",
                "optionValue": "Sri Lanka"
            },
            {
                "displayName": "Sudan",
                "optionValue": "Sudan"
            },
            {
                "displayName": "Suriname",
                "optionValue": "Suriname"
            },
            {
                "displayName": "Svalbard and Jan Mayen",
                "optionValue": "Svalbard and Jan Mayen"
            },
            {
                "displayName": "Swaziland",
                "optionValue": "Swaziland"
            },
            {
                "displayName": "Sweden",
                "optionValue": "Sweden"
            },
            {
                "displayName": "Switzerland",
                "optionValue": "Switzerland"
            },
            {
                "displayName": "Syrian Arab Republic",
                "optionValue": "Syrian Arab Republic"
            },
            {
                "displayName": "Taiwan, Province of China",
                "optionValue": "Taiwan, Province of China"
            },
            {
                "displayName": "Tajikistan",
                "optionValue": "Tajikistan"
            },
            {
                "displayName": "Tanzania, United Republic of",
                "optionValue": "Tanzania, United Republic of"
            },
            {
                "displayName": "Thailand",
                "optionValue": "Thailand"
            },
            {
                "displayName": "Timor-Leste",
                "optionValue": "Timor-Leste"
            },
            {
                "displayName": "Togo",
                "optionValue": "Togo"
            },
            {
                "displayName": "Tokelau",
                "optionValue": "Tokelau"
            },
            {
                "displayName": "Tonga",
                "optionValue": "Tonga"
            },
            {
                "displayName": "Trinidad and Tobago",
                "optionValue": "Trinidad and Tobago"
            },
            {
                "displayName": "Tunisia",
                "optionValue": "Tunisia"
            },
            {
                "displayName": "Turkey",
                "optionValue": "Turkey"
            },
            {
                "displayName": "Turkmenistan",
                "optionValue": "Turkmenistan"
            },
            {
                "displayName": "Turks and Caicos Islands",
                "optionValue": "Turks and Caicos Islands"
            },
            {
                "displayName": "Tuvalu",
                "optionValue": "Tuvalu"
            },
            {
                "displayName": "Uganda",
                "optionValue": "Uganda"
            },
            {
                "displayName": "Ukraine",
                "optionValue": "Ukraine"
            },
            {
                "displayName": "United Arab Emirates",
                "optionValue": "United Arab Emirates"
            },
            {
                "displayName": "United Kingdom",
                "optionValue": "United Kingdom"
            },
            {
                "displayName": "United States",
                "optionValue": "United States"
            },
            {
                "displayName": "United States Minor Outlying Islands",
                "optionValue": "United States Minor Outlying Islands"
            },
            {
                "displayName": "Uruguay",
                "optionValue": "Uruguay"
            },
            {
                "displayName": "Uzbekistan",
                "optionValue": "Uzbekistan"
            },
            {
                "displayName": "Vanuatu",
                "optionValue": "Vanuatu"
            },
            {
                "displayName": "Venezuela",
                "optionValue": "Venezuela"
            },
            {
                "displayName": "Viet Nam",
                "optionValue": "Viet Nam"
            },
            {
                "displayName": "Virgin Islands, British",
                "optionValue": "Virgin Islands, British"
            },
            {
                "displayName": "Virgin Islands, U.S.",
                "optionValue": "Virgin Islands, U.S."
            },
            {
                "displayName": "Wallis and Futuna",
                "optionValue": "Wallis and Futuna"
            },
            {
                "displayName": "Western Sahara",
                "optionValue": "Western Sahara"
            },
            {
                "displayName": "Yemen",
                "optionValue": "Yemen"
            },
            {
                "displayName": "Zambia",
                "optionValue": "Zambia"
            },
            {
                "displayName": "Zimbabwe",
                "optionValue": "Zimbabwe"
            },
        ]
    },
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const sex: FormConfigInterface = {
    label: 'Nem / Sex',
    elementType: 'radio',
    elementConfig: {
        options: [
            {
                displayName: 'Nő / Female',
                optionValue: 'Nő / Female'
            },
            {
                displayName: 'Férfi / Male',
                optionValue: 'Férfi / Male'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const email: FormConfigInterface = {
    label: 'E-mail cím / E-mail address',
    elementType: 'input',
    elementConfig: {
        type: 'email'
    },
    validation: {
        required: true,
        isEmail: true
    },
    ...defaultFormConfig
};

export const accreditation_front: FormConfigInterface = {
    label: 'Akkreditációs kártya eleje / Accreditation card front',
    elementType: 'picture',
    elementConfig: {},
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const accreditation_back: FormConfigInterface = {
    label: 'Akkreditációs kártya hátulja / Accreditation card back',
    elementType: 'picture',
    elementConfig: {},
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const patient_photo: FormConfigInterface = {
    label: 'Az ellátott személy / The person receiving care',
    elementType: 'picture',
    elementConfig: {},
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const other_photo: FormConfigInterface = {
    label: 'Egyéb fotó (pl. sérülés) / Other photo (e.g. injury)',
    elementType: 'picture',
    elementConfig: {},
    validation: {
        required: false,
    },
    ...defaultFormConfig,
    helper: 'A mező nem kötelező / Optional field'
};

export const privacy: FormConfigInterface = {
    label: 'Adatkezelési nyilatkozat elfogadása / Accept privacy statement',
    elementType: 'checkbox',
    elementConfig: {},
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const privacy_button: FormConfigInterface = {
    label: '',
    elementType: 'button',
    text: 'Adatkezelési nyilatkozat megtekintése / View privacy statement',
    elementConfig: {},
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const payment: FormConfigInterface = {
    label: 'Biztosítási feltételek elfogadása / Accept insurance policy',
    elementType: 'checkbox',
    elementConfig: {},
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const payment_button: FormConfigInterface = {
    label: '',
    elementType: 'button',
    text: 'Biztosítási feltételek / Insurance policy',
    elementConfig: {},
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const signature: FormConfigInterface = {
    label: 'Aláírás / Signature',
    elementType: 'signature',
    elementConfig: {
        colSize: '6'
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const patient_doctor_signature: FormConfigInterface = {
    label: 'Orvos aláírása / Physician’s signature',
    elementType: 'signature',
    elementConfig: {
        colSize: '6'
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const insurance_no: FormConfigInterface = {
    label: 'Biztosítás száma / Insurance number',
    helper: 'Ha a páciensnek nincs biztosítása írja be, hogy "Nincs"',
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const accreditation_no: FormConfigInterface = {
    label: 'Akkreditációs szám / Accreditation number',
    helper: 'Ha a páciensnek nincs akkreditációja írja be, hogy "Nincs"',
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const accreditation_type: FormConfigInterface = {
    label: 'Akkreditáció típusa / Accreditation type',
    elementType: 'radio',
    elementConfig: {
        options: [
            {
                displayName: 'Sportoló / Athlete',
                optionValue: 'Sportoló / Athlete'
            },
            {
                displayName: 'Egyéb / Other',
                optionValue: 'Egyéb / Other'
            },
            {
                displayName: 'Nincs / None',
                optionValue: 'Nincs / None'
            }
        ]
    },
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const sports: FormConfigInterface = {
    label: 'Sportesemény / Sport event',
    elementType: 'select',
    elementConfig: {
        multiple: false,
        options: []
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const complaints: FormConfigInterface = {
    label: 'Panaszok / Complaints',
    elementType: 'textarea',
    elementConfig: {
        rows: 4,
        translated: true
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const time_of_injury: FormConfigInterface = {
    label: 'Sérülés ideje / Time of Injury',
    elementType: 'radio',
    elementConfig: {
        options: [
            {
                displayName: 'Nincs / None',
                optionValue: 'Nincs / None'
            },
            {
                displayName: 'Elődöntő / Semifinal',
                optionValue: 'Elődöntő / Semifinal'
            },
            {
                displayName: 'Döntő / Finals',
                optionValue: 'Döntő / Finals'
            },
            {
                displayName: 'Edzés / Training',
                optionValue: 'Edzés / Training'
            },
            {
                displayName: 'Egyéb / Other',
                optionValue: 'Egyéb / Other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const location_of_injury_head: FormConfigInterface = {
    label: 'A sérülés helye (fej és törzs) / Location of injury (head and trunk)',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: '0 Nincs / none',
                optionValue: '0 Nincs / none'
            },
            {
                displayName: '1 fej (részletezze: szem, fül, orr, arc, fogazat) / head (specify: eye, ear, nose, face, dental)',
                optionValue: '1 fej (részletezze: szem, fül, orr, arc, fogazat) / head (specify: eye, ear, nose, face, dental)'
            },
            {
                displayName: '2	nyak, nyaki gerinc / neck, cervical spine',
                optionValue: '2	nyak, nyaki gerinc / neck, cervical spine'
            },
            {
                displayName: '3	mellkas, szegycsont, bordák / chest, sternum, ribs',
                optionValue: '3	mellkas, szegycsont, bordák / chest, sternum, ribs'
            },
            {
                displayName: '4	mellkasi gerinc, hát felső része / thoracic spine, upper back',
                optionValue: '4	mellkasi gerinc, hát felső része / thoracic spine, upper back '
            },
            {
                displayName: '5	ágyéki gerinc, deréktáj / lumbar spine, lower back',
                optionValue: '5	ágyéki gerinc, deréktáj / lumbar spine, lower back '
            },
            {
                displayName: '6 has / abdomen',
                optionValue: '6 has / abdomen'
            },
            {
                displayName: '7	medence, keresztcsont, farpofa / pelvis, sacrum, buttock',
                optionValue: '7	medence, keresztcsont, farpofa / pelvis, sacrum, buttock'
            },
            {
                displayName: '8 egyéb / other',
                optionValue: '8 egyéb / other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const location_of_injury_upper: FormConfigInterface = {
    label: 'Sérülés helye (felső végtag) / Location of injury (upper extremity)',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: '0 nincs / none',
                optionValue: '0 nincs / none'
            },
            {
                displayName: '1 váll (kulcscsonttal együtt) / shoulder (incl. clavicle)',
                optionValue: '1 váll (kulcscsonttal együtt) / shoulder (incl. clavicle)'
            },
            {
                displayName: '2 felkara / upper arm',
                optionValue: '2 felkara / upper arm'
            },
            {
                displayName: '3 könyök / elbow',
                optionValue: '3 könyök / elbow'
            },
            {
                displayName: '4 alkar / forearm',
                optionValue: '4 alkar / forearm '
            },
            {
                displayName: '5 csukló / wrist',
                optionValue: '5 csukló / wrist '
            },
            {
                displayName: '6 kézfej / hand',
                optionValue: '6 kézfej / hand'
            },
            {
                displayName: '7 ujj, hüvelykujj / finger, thumb',
                optionValue: '7 ujj, hüvelykujj / finger, thumb'
            },
            {
                displayName: '8 egyéb / other',
                optionValue: '8 egyéb / other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const location_of_injury_lower: FormConfigInterface = {
    label: 'Sérülés helye (alsó végtag) / Location of injury (lower extremity)',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: '0 nincs / none',
                optionValue: '0 nincs / none'
            },
            {
                displayName: '1 csípő, ágyék / hip, groin',
                optionValue: '1 csípő, ágyék / hip, groin'
            },
            {
                displayName: '2 comb / thigh',
                optionValue: '2 comb / thigh'
            },
            {
                displayName: '3 térd / knee',
                optionValue: '3 térd / knee'
            },
            {
                displayName: '4 alsó lábszár, Achilles-ín / lower leg, Achilles tendon',
                optionValue: '4 alsó lábszár, Achilles-ín / lower leg, Achilles tendon '
            },
            {
                displayName: '5 boka / ankle',
                optionValue: '5 boka / ankle '
            },
            {
                displayName: '6 lábfej, lábujj / foot, toe',
                optionValue: '6 lábfej, lábujj / foot, toe'
            },
            {
                displayName: '7 egyéb / other',
                optionValue: '7 egyéb / other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const type_of_injury: FormConfigInterface = {
    label: 'Sérülés típusa / type of injury',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: '0 nincs / none',
                optionValue: '0 nincs / none'
            },
            {
                displayName: '1 agyrázkódás (eszméletvesztéssel vagy anélkül) / concussion (regardless of loss of consciousness)',
                optionValue: '1 agyrázkódás (eszméletvesztéssel vagy anélkül / concussion (regardless of loss of consciousness)'
            },
            {
                displayName: '2 törés (traumás) / fracture (traumatic)',
                optionValue: '2 törés (traumás) / fracture (traumatic)'
            },
            {
                displayName: '3 Fáradásostörés (túlterhelés) / stress fracture (overuse)',
                optionValue: '3 Fáradásostörés (túlterhelés) / stress fracture (overuse)'
            },
            {
                displayName: '4 egyéb csontsérülés / other bone injuries',
                optionValue: '4 egyéb csontsérülés / other bone injuries'
            },
            {
                displayName: '5 diszlokáció, szubluxáció / dislocation, subluxation',
                optionValue: '5 diszlokáció, szubluxáció / dislocation, subluxation'
            },
            {
                displayName: '6 ínszalag szakadás / tendon rupture',
                optionValue: '6 ínszalag szakadás / tendon rupture'
            },
            {
                displayName: '7	szalagsérülés / Ligamentous rupture',
                optionValue: '7	szalagsérülés / Ligamentous rupture'
            },
            {
                displayName: '8 rándulás (ízület és/vagy szalagok sérülése) / sprain (injury of joint and/or ligaments)',
                optionValue: '8 rándulás (ízület és/vagy szalagok sérülése) / sprain (injury of joint and/or ligaments)'
            },
            {
                displayName: '9 meniszkusz- vagy porcelváltozás / lesion of meniscus or cartilage',
                optionValue: '9 meniszkusz- vagy porcelváltozás / lesion of meniscus or cartilage'
            },
            {
                displayName: '10 ficam, izomszakadás, repedés / strain, muscle rupture, tear',
                optionValue: '10 ficam, izomszakadás, repedés / strain, muscle rupture, tear'
            },
            {
                displayName: '11 contusio, haematoma, zúzódás / contusion, haematoma, bruise',
                optionValue: '11 contusio, haematoma, zúzódás / contusion, haematoma, bruise'
            },
            {
                displayName: '12 laceráció, horzsolás, bőrelváltozás / laceration, abrasion, skin lesion',
                optionValue: '12 laceráció, horzsolás, bőrelváltozás / laceration, abrasion, skin lesion'
            },
            {
                displayName: '13 idegkárosodás, gerincvelő-sérülés / nerve injury, spinal cord injury',
                optionValue: '13 idegkárosodás, gerincvelő-sérülés / nerve injury, spinal cord injury'
            },
            {
                displayName: '14 fogsérülés, törött fog / dental injury, broken tooth',
                optionValue: '14 fogsérülés, törött fog / dental injury, broken tooth'
            },
            {
                displayName: '15 tendinosis, ínbetegség / tendinosis, tendinopathy',
                optionValue: '15 tendinosis, ínbetegség / tendinosis, tendinopathy'
            },
            {
                displayName: '16 arthritis, synovitis, bursitis / arthritis, synovitis, bursitis',
                optionValue: '16 arthritis, synovitis, bursitis / arthritis, synovitis, bursitis'
            },
            {
                displayName: '17 fasciitis, aponeurózis sérülés / fasciitis, aponeurosis injury',
                optionValue: '17 fasciitis, aponeurózis sérülés / fasciitis, aponeurosis injury'
            },
            {
                displayName: '18 becsípődés / impingement',
                optionValue: '18 becsípődés / impingement'
            },
            {
                displayName: '19 epifízis korong zavara, avulzió / growth plate disturbance, avulsion',
                optionValue: '19 epifízis korong zavara, avulzió / growth plate disturbance, avulsion'
            },
            {
                displayName: '20 izomgörcs vagy spazmus / muscle cramps or spasm',
                optionValue: '20 izomgörcs vagy spazmus / muscle cramps or spasm'
            },
            {
                displayName: '21 egyéb / other',
                optionValue: '21 egyéb / other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const cause_of_injury: FormConfigInterface = {
    label: 'Sérülés oka / Cause of injury',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: '0 nincs / none',
                optionValue: '0 nincs / none'
            },
            {
                displayName: '1 túlterhelés (fokozatosan kialakuló) / overuse (gradual onset)',
                optionValue: '1 túlterhelés (fokozatosan kialakuló) / overuse (gradual onset)'
            },
            {
                displayName: '2 túlterhelés (hirtelen kialakuló) / overuse (sudden onset)',
                optionValue: '2 túlterhelés (hirtelen kialakuló) / overuse (sudden onset)'
            },
            {
                displayName: '3 non-kontakt sérülés / non-contact trauma',
                optionValue: '3 non-kontakt sérülés / non-contact trauma'
            },
            {
                displayName: '4 korábbi sérülés kiújulása / recurrence of previous injury',
                optionValue: '4 korábbi sérülés kiújulása / recurrence of previous injury'
            },
            {
                displayName: '5 kontakt másik sportolóval / contact with another athlete',
                optionValue: '5 kontakt másik sportolóval / contact with another athlete'
            },
            {
                displayName: '6 kontakt mozgó tárggyal (pl. labda) / contact: moving object (e.g. ball)',
                optionValue: '6 kontakt mozgó tárggyal (pl. labda) / contact: moving object (e.g. ball)'
            },
            {
                displayName: '7 kontakt álló tárggyal (pl. fal) / contact: stagnant object (e.g. wall)',
                optionValue: '7 kontakt álló tárggyal (pl. fal) / contact: stagnant object (e.g. wall)'
            },
            {
                displayName: '8 szabályszegés (csalás) / violation of rules (foul play)',
                optionValue: '8 szabályszegés (csalás) / violation of rules (foul play)'
            },
            {
                displayName: '9 játéktér körülményei / field of play conditions',
                optionValue: '9 játéktér körülményei / field of play conditions'
            },
            {
                displayName: '10 időjárási körülmények / weather condition',
                optionValue: '10 időjárási körülmények / weather condition'
            },
            {
                displayName: '11 berendezés meghibásodása / equipment failure',
                optionValue: '11 berendezés meghibásodása / equipment failure'
            },
            {
                displayName: '12 egyéb / other',
                optionValue: '12 egyéb / other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const absence_in_days: FormConfigInterface = {
    label: 'Versenytől való távollét / Absence in days',
    elementType: 'select',
    elementConfig: {
        multiple: false,
        options: [
            {
                displayName: 'nincs / none',
                optionValue: 'nincs / none'
            },
            {
                displayName: '0 nap / 0 day',
                optionValue: '0 nap / 0 day'
            },
            {
                displayName: '1 nap / 1 day',
                optionValue: '1 nap / 1 day'
            },
            {
                displayName: '2 nap / 2 day',
                optionValue: '2 nap / 2 day'
            },
            {
                displayName: '1 hét / 1 weeks',
                optionValue: '1 hét / 1 weeks'
            },
            {
                displayName: '2 hét / 2 weeks',
                optionValue: '2 hét / 2 weeks'
            },
            {
                displayName: '3 hét / 3 weeks',
                optionValue: '3 hét / 3 weeks'
            },
            {
                displayName: '4 hét / 4 weeks',
                optionValue: '4 hét / 4 weeks'
            },
            {
                displayName: 'több mint 4 hét / more than 4 weeks',
                optionValue: 'több mint 4 hét / more than 4 weeks'
            },
            {
                displayName: '6 hónap, vagy annál több / 6 months or more',
                optionValue: '6 hónap, vagy annál több / 6 months or more'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const affected_system: FormConfigInterface = {
    label: 'A betegség miatt érintett rendszerek / Affected system of illness',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: '0 nincs / none',
                optionValue: '0 nincs / none'
            },
            {
                displayName: '1 felső légutak (orr, orrmelléküreg, garat, gég) / upper respiratory tract (nose, sinuses, pharynx, larynx)',
                optionValue: '1 felső légutak (orr, orrmelléküreg, garat, gég) / upper respiratory tract (nose, sinuses, pharynx, larynx)'
            },
            {
                displayName: '2 alsó légutak (légcső hörgők, tüdő) / lower respiratory tract (trachea bronchi, lungs)',
                optionValue: '2 alsó légutak (légcső hörgők, tüdő) / lower respiratory tract (trachea bronchi, lungs)'
            },
            {
                displayName: '3 gyomor-bélrendszeri / gastro-intestinal',
                optionValue: '3 gyomor-bélrendszeri / gastro-intestinal'
            },
            {
                displayName: '4 szív- és érrendszeri / cardio-vascular',
                optionValue: '4 szív- és érrendszeri / cardio-vascular'
            },
            {
                displayName: '5 urogenitális, nőgyógyászati / uro-genital, gynecological',
                optionValue: '5 urogenitális, nőgyógyászati / uro-genital, gynecological'
            },
            {
                displayName: '6 metabolikus, endokrin / metabolic, endocrinological',
                optionValue: '6 metabolikus, endokrin / metabolic, endocrinological'
            },
            {
                displayName: '7 allergiás, immunrendszeri / allergic, immunological',
                optionValue: '7 allergiás, immunrendszeri / allergic, immunological'
            },
            {
                displayName: '8 hematológiai / hematological',
                optionValue: '8 hematológiai / hematological'
            },
            {
                displayName: '9 dermatológiai / dermatologic',
                optionValue: '9 dermatológiai / dermatologic'
            },
            {
                displayName: '10 izom- és vázrendszeri / muscular-skeletal',
                optionValue: '10 izom- és vázrendszeri / muscular-skeletal'
            },
            {
                displayName: '11 szemészeti, otológiai / ophthalmological, ontological',
                optionValue: '11 szemészeti, otológiai / ophthalmological, ontological'
            },
            {
                displayName: '12 fogászati / dental',
                optionValue: '12 fogászati / dental'
            },
            {
                displayName: '13 neurologiai, központi idegrendszeri / neurological, CNS',
                optionValue: '13 neurologiai, központi idegrendszeri / neurological, CNS'
            },
            {
                displayName: '14 pszichiátriai, pszichológiai / psychiatric, psychological',
                optionValue: '14 pszichiátriai, pszichológiai / psychiatric, psychological'
            },
            {
                displayName: '15 egyéb / other',
                optionValue: '15 egyéb / other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const main_symptoms: FormConfigInterface = {
    label: 'Fő tünet(ek) / Main symptom(s)',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: '0 nincs / none',
                optionValue: '0 nincs / none'
            },
            {
                displayName: '1 fájdalom, fájás, nyilallás / pain, ache, soreness',
                optionValue: '1 fájdalom, fájás, nyilallás / pain, ache, soreness'
            },
            {
                displayName: '2 láz, hidegrázás / fever, chills',
                optionValue: '2 láz, hidegrázás / fever, chills'
            },
            {
                displayName: '3 hányinger, hányás, hasmenés / nausea, vomiting, diarrhea',
                optionValue: '3 hányinger, hányás, hasmenés / nausea, vomiting, diarrhea'
            },
            {
                displayName: '4 köhögés, zihálás, nehézlégzés / cough, wheezing, dyspnea',
                optionValue: '4 köhögés, zihálás, nehézlégzés / cough, wheezing, dyspnea'
            },
            {
                displayName: '5 légszomj / shortness of breath',
                optionValue: '5 légszomj / shortness of breath'
            },
            {
                displayName: '6 szabálytalan szívverés, palpitáció / irregular heartbeat, palpitation',
                optionValue: '6 szabálytalan szívverés, palpitáció / irregular heartbeat, palpitation'
            },
            {
                displayName: '7 dehidratáció, túlzott izzadás / dehydration, excess sweating',
                optionValue: '7 dehidratáció, túlzott izzadás / dehydration, excess sweating'
            },
            {
                displayName: '8 kiütés, viszketés, ekcéma / rash, itch, eczema',
                optionValue: '8 kiütés, viszketés, ekcéma / rash, itch, eczema'
            },
            {
                displayName: '9 orrdugulás, orrfolyás / rasal congestion, rhinorrhoea',
                optionValue: '9 orrdugulás, orrfolyás / rasal congestion, rhinorrhoea'
            },
            {
                displayName: '10 szédülés, vertigo / dizziness, vertigo',
                optionValue: '10 szédülés, vertigo / dizziness, vertigo'
            },
            {
                displayName: '11 ájulás, eszméletvesztés / fainting, syncope',
                optionValue: '11 ájulás, eszméletvesztés / fainting, syncope'
            },
            {
                displayName: '12 zsibbadás, gyengeség, bizsergés / numbness, weakness, tingling',
                optionValue: '12 zsibbadás, gyengeség, bizsergés / numbness, weakness, tingling'
            },
            {
                displayName: '13 fáradtság, energiahiány, letargia / fatigue, lack of energy, lethargy',
                optionValue: '13 fáradtság, energiahiány, letargia / fatigue, lack of energy, lethargy'
            },
            {
                displayName: '14 alvászavar / sleep disturbance',
                optionValue: '14 alvászavar / sleep disturbance'
            },
            {
                displayName: '15 pszichol. problémák, szorongás, depresszió / psychological problems, anxiety, depression',
                optionValue: '15 pszichol. problémák, szorongás, depresszió / psychological problems, anxiety, depression'
            },
            {
                displayName: '16 egyéb / other',
                optionValue: '16 egyéb / other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const cause_of_symptoms: FormConfigInterface = {
    label: 'Betegség, tünetek oka(i) / Cause of illness, symptom(s)',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: '0 nincs / none',
                optionValue: '0 nincs / none'
            },
            {
                displayName: '1 fennálló (pl. asztma, allergia) / pre-existing (e.g. asthma, allergy)',
                optionValue: '1 fennálló (pl. asztma, allergia) / pre-existing (e.g. asthma, allergy)'
            },
            {
                displayName: '2 fertőzés / infection',
                optionValue: '2 fertőzés / infection'
            },
            {
                displayName: '3 testedzés-okozta / exercise-induced',
                optionValue: '3 testedzés-okozta / exercise-induced'
            },
            {
                displayName: '4 környezeti / environmental',
                optionValue: '4 környezeti / environmental'
            },
            {
                displayName: '5 gyógyszerreakció / reaction to medication',
                optionValue: '5 gyógyszerreakció / reaction to medication'
            },
            {
                displayName: '6 egyéb / other',
                optionValue: '6 egyéb / other'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const drug_allergies: FormConfigInterface = {
    label: 'Gyógyszerallergia / Drug allergies',
    elementType: 'textarea',
    elementConfig: {
        rows: 4,
        translated: true,
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const relevant_history: FormConfigInterface = {
    label: 'Kórelőzmény / Relevant history',
    elementType: 'textarea',
    elementConfig: {
        rows: 4,
        translated: true,
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const home_medications: FormConfigInterface = {
    label: 'Otthoni gyógyszerek / Home medications',
    elementType: 'textarea',
    elementConfig: {
        rows: 4,
        translated: true,
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const respiratory_rate_1: FormConfigInterface = {
    label: 'Légzésszám / Respiratory rate',
    helper: '/min <small>(Kitöltése nem kötelező)</small>',
    elementType: 'input',
    elementConfig: {
        type: 'number'
    },
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const pulse_rate_1: FormConfigInterface = {
    label: 'Pulzusszám / Pulse rate',
    helper: '/min <small>(Kitöltése nem kötelező)</small>',
    elementType: 'input',
    elementConfig: {
        type: 'number'
    },
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const blood_pressure_1_1: FormConfigInterface = {
    label: 'Szisztolé Vérnyomás / Systolic Blood pressure',
    helper: 'mmHg <small>(Kitöltése nem kötelező)</small>',
    elementType: 'input',
    elementConfig: {
        type: 'number',
        colSize: '6',
    },
    validation: {
        required: false,
        isNumeric: true,
        minValue: 0,
        maxValue: 350,
    },
    ...defaultFormConfig
};

export const blood_pressure_1_2: FormConfigInterface = {
    label: 'Diasztolé  Vérnyomás / Diastolic Blood pressure',
    helper: 'mmHg <small>(Kitöltése nem kötelező)</small>',
    elementType: 'input',
    elementConfig: {
        type: 'number',
        colSize: '6',
    },
    validation: {
        required: false,
        isNumeric: true,
        minValue: 0,
        maxValue: 350,
    },
    ...defaultFormConfig
};

export const spo2_1: FormConfigInterface = {
    label: 'SpO2',
    helper: '0-100% <small>(Kitöltése nem kötelező)</small>',
    elementType: 'input',
    elementConfig: {
        type: 'number'
    },
    validation: {
        required: false,
        isNumeric: true,
        minValue: 0,
        maxValue: 100,
    },
    ...defaultFormConfig
};

export const pain_1: FormConfigInterface = {
    label: 'Fájdalom (VAS) / Pain (VAS)',
    helper: '1-10 <small>(Kitöltése nem kötelező)</small>',
    elementType: 'input',
    elementConfig: {
        type: 'number'
    },
    validation: {
        required: false,
        isNumeric: true,
        minValue: 1,
        maxValue: 10,
    },
    ...defaultFormConfig
};

export const body_temperature_1: FormConfigInterface = {
    label: 'Testhőmérséklet / Body temperature',
    helper: '°C <small>(Kitöltése nem kötelező)</small>',
    elementType: 'input',
    elementConfig: {
        type: 'number'
    },
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const button_behavior: FormConfigInterface = {
    label: 'Viselkedési tesztek / Behaviour Tests',
    text: 'Viselkedési tesztek / Behaviour Tests <br/> Amennyiben a sérülés jellege és/vagy a páciens állapota megköveteli, jelen menüpontnál felvehetőek a viselkedést vizsgáló tesztek. <span style="color: #3880ff; text-decoration: underline;">Kattintson ide a tesztek megjelenítéséhez.</span>',
    helper: 'Amennyiben a sérülés jellege és/vagy a páciens állapota megköveteli, jelen menüpontnál felvehetőek a viselkedést vizsgáló tesztek.',
    elementType: 'button',
    elementConfig: {
        type: 'text',
        displayedRows: [],
        hide: false,
        hideLabel: true,
        colSize: '12',
        buttonIcon: 'add',
        showIfAllRowsDisplayed: true,
    },
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const behavior_test_1: FormConfigInterface = {
    label: 'Szemnyitási válasz / Eye Opening Response test',
    elementType: 'radio',
    elementConfig: {
        row: 'behaviour_test',
        button: 'button_behavior',
        options: [
            {
                displayName: '0. Nem tesztelt / Not tested',
                optionValue: '0. Nem tesztelt / Not tested'
            },
            {
                displayName: '1. Semmilyen stimulusra / No response',
                optionValue: '1. Semmilyen stimulusra / No response'
            },
            {
                displayName: '2. Fájdalomingerre / To Pain',
                optionValue: '2. Fájdalomingerre / To Pain'
            },
            {
                displayName: '3. Megszólításra / To speech',
                optionValue: '3. Megszólításra / To speech'
            },
            {
                displayName: '4. Spontán / Spontaneously',
                optionValue: '4. Spontán / Spontaneously'
            }
        ]
    },
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const behavior_test_2: FormConfigInterface = {
    label: 'Verbális válasz / Verbal Response test',
    elementType: 'radio',
    elementConfig: {
        row: 'behaviour_test',
        button: 'button_behavior',
        options: [
            {
                displayName: '0. Nem tesztelt / Not tested',
                optionValue: '0. Nem tesztelt / Not tested'
            },
            {
                displayName: '1. Semmilyen verbális kommunikációra sem képes / No response',
                optionValue: '1. Semmilyen verbális kommunikációra sem képes / No response'
            },
            {
                displayName: '2. Érthetetlen hangokat ad ki / Incomprehensible sounds',
                optionValue: '2. Érthetetlen hangokat ad ki / Incomprehensible sounds'
            },
            {
                displayName: '3. Nem megfelelő szavakat használ / Inappropriate words',
                optionValue: '3. Nem megfelelő szavakat használ / Inappropriate words'
            },
            {
                displayName: '4. Zavart / Confused',
                optionValue: '4. Zavart / Confused'
            },
            {
                displayName: '5. Időben, személyhez és helyhez orientált / Oriented to time, person and place',
                optionValue: '5. Időben, személyhez és helyhez orientált / Oriented to time, person and place'
            }
        ]
    },
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const behavior_test_3: FormConfigInterface = {
    label: 'Motoros válasz / Motor Response test',
    elementType: 'radio',
    elementConfig: {
        row: 'behaviour_test',
        button: 'button_behavior',
        options: [
            {
                displayName: '0. Nem tesztelt / Not tested',
                optionValue: '0. Nem tesztelt / Not tested'
            },
            {
                displayName: '1. Semmilyen motorikus válaszra sem képes / No response',
                optionValue: '1. Semmilyen motorikus válaszra sem képes / No response'
            },
            {
                displayName: '2. Abnormális extenzió / Abnormal extension',
                optionValue: '2. Abnormális extenzió / Abnormal extension'
            },
            {
                displayName: '3. Abnormális flexió / Abnormal flexion',
                optionValue: '3. Abnormális flexió / Abnormal flexion'
            },
            {
                displayName: '4. Célzott fájdalomelhárítás / Flex to withdraw form pain',
                optionValue: '4. Célzott fájdalomelhárítás / Flex to withdraw form pain'
            },
            {
                displayName: '5. Lokalizálja a fájdalmat / Moves to localised pain',
                optionValue: '5. Lokalizálja a fájdalmat / Moves to localised pain'
            },
            {
                displayName: '6. Végrehajtja az utasításokat / Obeys command',
                optionValue: '6. Végrehajtja az utasításokat / Obeys command'
            }
        ]
    },
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const text: FormConfigInterface = {
    label: '',
    elementType: 'text',
    text: 'Tudomásul veszem, hogy a javasolt gyógyszer(ek) közül a jelzett(ek) WADA tiltólistán van(nak), alternatíva nem áll rendelkezésre, beadása nélkül egészségügyi állapotom javulása elmaradhat, vagy kisebb mértékben, vagy hosszabb idő alatt következne be. Tudomásul veszem, hogy a javasolt gyógyszer számomra, jelen állapotomban javasolt dosisa a WADA szabályozásban megállapított határértéket meghaladhatja. <br><br> I acknowledge that from the recommended drug(s), the indicated one(s) is/are on the WADA Prohibited List, no alternative is available, and without the administration of this/these drug(s) my health may not improve, or to a lesser extent or over a longer period of time. I acknowledge that in my current state, the recommended dose to be administered may exceed the limit set by the WADA regulations.',
    elementConfig: {},
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const radio: FormConfigInterface = {
    label: 'Nyilatkozat / Declaration',
    elementType: 'radio',
    elementConfig: {
        options: [
            {
                displayName: 'Ennek tudatában a kezelést kifejezetten kérem és elfogadom. / In view of this, I specifically request and accept treatment.',
                optionValue: 'Ennek tudatában a kezelést kifejezetten kérem és elfogadom. / In view of this, I specifically request and accept treatment.'
            },
            {
                displayName: 'Ennek tudatában a kezelést elutasítom. A kezelés elmaradásának lehetséges következményeit megértettem. / In view of this, I refuse treatment. I understand the possible consequences of refusing treatment.',
                optionValue: 'Ennek tudatában a kezelést elutasítom. A kezelés elmaradásának lehetséges következményeit megértettem. / In view of this, I refuse treatment. I understand the possible consequences of refusing treatment.'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const drugs_signature: FormConfigInterface = {
    label: 'Páciens aláírása / Patient signature',
    elementType: 'signature',
    elementConfig: {
        colSize: '6'
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const drugs_signature_doctor: FormConfigInterface = {
    label: 'Doktor aláírása / Doctor signature',
    elementType: 'signature',
    elementConfig: {
        colSize: '6'
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const notes: FormConfigInterface = {
    label: 'Megjegyzések, ajánlások, utasítások / Notes, recommendations, instructions',
    elementType: 'textarea',
    elementConfig: {
        rows: 4,
        translated: true,
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const type_of_care: FormConfigInterface = {
    label: '',
    elementType: 'radio',
    elementConfig: {
        options: [
            {
                displayName: 'A (csak konzultáció / consultant only)',
                optionValue: 'A (csak konzultáció / consultant only)'
            },
            {
                displayName: 'B (A + gyógyszer vagy egyéb fogyasztás / drug or other consumption)',
                optionValue: 'B (A + gyógyszer vagy egyéb fogyasztás / drug or other consumption)'
            },
            {
                displayName: 'C (B + a szolgáltatás helyén elvégzett vizsgálatok – pl. vérnyomás, SpO2, stb / point of care tests - e.g. BP, SpO2, etc)',
                optionValue: 'C (B + a szolgáltatás helyén elvégzett vizsgálatok – pl. vérnyomás, SpO2, stb / point of care tests - e.g. BP, SpO2, etc)'
            },
            {
                displayName: 'D (C + szállítás további kezelésre / C + transport for further treatment)',
                optionValue: 'D (C + szállítás további kezelésre / C + transport for further treatment)'
            },
            {
                displayName: 'E (szállítás további kezelésre / transport for further treatment)',
                optionValue: 'E (szállítás további kezelésre / transport for further treatment)'
            },
            {
                displayName: 'F (szállítás mentővel / transport by ambulance)',
                optionValue: 'F (szállítás mentővel / transport by ambulance)'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const admission_to: FormConfigInterface = {
    label: '',
    elementType: 'radio',
    elementConfig: {
        options: [
            {
                displayName: 'A rendezvény helyszíne',
                optionValue: 'A rendezvény helyszíne'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const athlete_signature: FormConfigInterface = {
    label: 'Páciens aláírás / Patient signature',
    elementType: 'signature',
    elementConfig: {
        colSize: '6'
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const doctor_signature: FormConfigInterface = {
    label: 'Orvos aláírása / Physician’s signature',
    elementType: 'signature',
    elementConfig: {
        colSize: '6'
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const patient_signature_covid: FormConfigInterface = {
    label: 'Aláírás / Signature',
    elementType: 'signature',
    elementConfig: {
        colSize: '6'
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const covid_button: FormConfigInterface = {
    label: '',
    elementType: 'button',
    text: 'Covid ügyfélnyilatkozat megtekintése / View covid statement',
    elementConfig: {},
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const covid_consent: FormConfigInterface = {
    label: 'A Covid ügyfélnyilatkozat elfogadása / Accept Covid statement',
    elementType: 'checkbox',
    elementConfig: {},
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const custom_email: FormConfigInterface = {
    label: 'Egyéb email címek',
    helper: 'Sorolja fel az email címeket vesszővel elválasztva. Pl.: email1@email.com, email2@email.com',
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    validation: {
        required: false,
        editable: true
    },
    ...defaultFormConfig
};

export const name: FormConfigInterface = {
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    label: 'Esemény',
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const name_en: FormConfigInterface = {
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    label: 'Esemény angolul',
    validation: {
        required: false,
    },
    ...defaultFormConfig
};

export const password: FormConfigInterface = {
    elementType: 'input',
    elementConfig: {
        type: 'password'
    },
    label: 'Jelszó',
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const emails: FormConfigInterface = {
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    helper: 'Megadható több email cím vesszővel elválasztva pl.: email1@email.com, email2@email.com',
    label: 'Szervezők email címei',
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const start: FormConfigInterface = {
    elementType: 'date',
    elementConfig: {
        type: 'text',
        minDateType: 'present',
        maxDateType: 'future',
    },
    label: 'Kezdő dátum',
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const end: FormConfigInterface = {
    elementType: 'date',
    elementConfig: {
        type: 'text',
        maxDate: false,
        minDateType: 'present',
        maxDateType: 'future',
    },
    label: 'Befejező dátum',
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const address: FormConfigInterface = {
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    label: 'Helyszín',
    validation: {
        required: true
    },
    valid: false,
    touched: false,
    ...defaultFormConfig
};

export const city: FormConfigInterface = {
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    label: 'Város',
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const event_sports: FormConfigInterface = {
    label: 'Sportágak',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: [
            {
                displayName: 'Úszás',
                optionValue: 'Úszás'
            },
            {
                displayName: 'Vizilabda',
                optionValue: 'Vizilabda'
            },
            {
                displayName: 'Műugrás',
                optionValue: 'Műugrás'
            },
            {
                displayName: 'Szinkronúszás',
                optionValue: 'Szinkronúszás'
            },
            {
                displayName: 'Nyíltvízi úszás',
                optionValue: 'Nyíltvízi úszás'
            }
        ]
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const hospital: FormConfigInterface = {
    label: 'Klinikák',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: []
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const accreditation: FormConfigInterface = {
    label: 'Akkreditáció típusa',
    elementType: 'select',
    elementConfig: {
        multiple: true,
        options: []
    },
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const logo: FormConfigInterface = {
    label: 'Logó',
    elementType: 'image',
    elementConfig: {},
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const hospital_emails: FormConfigInterface = {
    elementType: 'input',
    elementConfig: {
        type: 'text',
        email: true
    },
    label: 'Klinika email címe',
    helper: 'Megadható több email cím vesszővel elválasztva pl.: email1@email.com, email2@email.com',
    validation: {
        required: true,
    },
    ...defaultFormConfig
};

export const username: FormConfigInterface = {
    label: 'Felhasználónév / pecsétszám',
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    validation: {
        required: true
    },
    ...defaultFormConfig
};

export const seal_number: FormConfigInterface = {
    elementType: 'input',
    elementConfig: {
        type: 'text'
    },
    label: 'Pecsét szám',
    validation: {
        required: false
    },
    ...defaultFormConfig
};

export const covid_text: FormConfigInterface = {
    elementType: 'texteditor',
    elementConfig: {
        type: 'text'
    },
    label: 'Covid nyilatkozat szövege',
    validation: {
        required: true,
        textEditor: true
    },
    valid: false,
    touched: false,
    ...defaultFormConfig
}

export const insurance_text: FormConfigInterface = {
    elementType: 'texteditor',
    elementConfig: {
        type: 'text'
    },
    label: 'Biztosítási feltételek szövege',
    validation: {
        required: true,
        textEditor: true
    },
    valid: false,
    touched: false,
    ...defaultFormConfig
}

export const insurance_text_en: FormConfigInterface = {
    elementType: 'texteditor',
    elementConfig: {
        type: 'text'
    },
    label: 'Biztosítási feltételek szövege ANGOLUL',
    validation: {
        required: true,
        textEditor: true
    },
    valid: false,
    touched: false,
    ...defaultFormConfig
}
