import {FormDataInterface} from "../../../../declarations";
import {
    behavior_test_1, behavior_test_2, behavior_test_3,
    blood_pressure_1_1, blood_pressure_1_2,
    body_temperature_1,
    button_behavior, pain_1,
    pulse_rate_1,
    respiratory_rate_1,
    spo2_1
} from "../../../inputFields/inputFields";

const medical_examinations: FormDataInterface = {
    respiratory_rate_1,
    pulse_rate_1,
    blood_pressure_1_1,
    blood_pressure_1_2,
    spo2_1,
    pain_1,
    body_temperature_1,
    button_behavior,
    behavior_test_1,
    behavior_test_2,
    behavior_test_3
};

export default medical_examinations;
