import React from 'react';
import {ChartInterface} from "../../store/declarations";
import {IonFab, IonFabButton, IonIcon, IonItem, IonList} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {add} from "ionicons/icons";
import {uuid} from 'uuidv4';
// @ts-ignore
import swal from '@sweetalert/with-react';

import Content from "../Content/Content";
import classes from "./ChartList.module.scss";
import {
    CERTIFICATE_FORM,
    COVID_FORM,
    EXAMINATION_FORM,
    MEDICINE_FORM,
    PATIENT_FORM,
    PATIENT_SHORT_FORM
} from "../../utils/functions/constants";
import {
    getChartListTitle,
    getMainFormByChartHash,
    initNewChart,
    navigationToChardEditPage
} from "../../utils/functions/chart";
import {ChartEditRouteParams} from "../../store/declarations/route";
import {FIRST_FORM_PAGE_INDEX} from "../../utils/consants/charts";
import {role} from "../../utils/consants/auth";
import Spinner from "../Spinner/Spinner";
import Guard from "../../hoc/guard/guard";
import {FormTypes} from "../../store/types";
import {ChartState, EventState} from "../../store/states";
import {routes} from "../../utils/consants/routes";

interface ChartListProps extends RouteComponentProps<{
    chartListType: string;
}> {}

const ChartList: React.FC<ChartListProps> = (props) => {
    const {setCharts, charts, isChartsLoading, createNewChart, createNewFormForChart, setShortFormToStandardAndUnlock} = ChartState();
    const {event} = EventState();

    const {history, match} = props;

    const initNewSubFormOnClickHandler = async (chartHash: string, formType: FormTypes) => {
        const formHash = uuid();
        const routeParams: ChartEditRouteParams = {
            chartHash,
            formType,
            formHash,
            formPageIndex: FIRST_FORM_PAGE_INDEX
        };

        await createNewFormForChart(chartHash, formHash, formType);
        goToEditPage(routeParams);
    };

    const editPatientFormOnclickHandler = (chartHash: string) => {
        const form = getMainFormByChartHash(charts, chartHash);
        const formType = form?.type;
        const formHash = form?.hash;
        const routeParams: ChartEditRouteParams = {
            chartHash,
            formType,
            formHash,
            formPageIndex: FIRST_FORM_PAGE_INDEX
        };

        goToEditPage(routeParams);
    };

    const editExaminationFormOnclickHandler = (chartHash: string, formHash: any, formType: FormTypes) => {
        const routeParams: ChartEditRouteParams = {
            chartHash,
            formType,
            formHash,
            formPageIndex: FIRST_FORM_PAGE_INDEX
        };

        goToEditPage(routeParams);
    };

    const chooseNewSubFormType = (chartHash: string) => {
        swal({
            title: 'Milyen formot szeretne létrehozni?',
            buttons: {
                confirm: {
                    text: 'Vizsgálat',
                    value: EXAMINATION_FORM
                },
                otherConfirm: {
                    text: 'Gyógyszerkiadás',
                    value: MEDICINE_FORM
                }
            },
        }).then(async (chosenFormType: any) => {
            if (chosenFormType) {
                const {type: formType, hash: formHash} = getMainFormByChartHash(charts, chartHash);
                if (chosenFormType === EXAMINATION_FORM && formType === PATIENT_SHORT_FORM) {
                    await setShortFormToStandardAndUnlock(chartHash);
                    navigationToChardEditPage(history, {
                        chartHash,
                        formType: PATIENT_FORM,
                        formHash,
                        formPageIndex: FIRST_FORM_PAGE_INDEX
                    });
                    return;
                }
                initNewSubFormOnClickHandler(chartHash, chosenFormType);
            }
        });
    };

    const onClickHandlers = {
        editPatientFormOnclickHandler,
        editExaminationFormOnclickHandler,
        chooseNewSubFormType
    };

    const goToEditPage = (routeParams: ChartEditRouteParams) => navigationToChardEditPage(history, routeParams);

    const showExaminationList = (chartHash: string) => {
        const newChartList = charts.map((chart: ChartInterface) => {
            if (chart.hash === chartHash) {
                chart.showExaminations = !chart.showExaminations;
            }

            return chart;
        });

        setCharts(newChartList);
    };

    const getNewChartOptions = () => {
        if (props.match.params.chartListType !== COVID_FORM) {
            return {
                confirm: {
                    text: 'Vizsgálat',
                    value: PATIENT_FORM
                },
                otherConfirm: {
                    text: 'Gyógyszerkiadás',
                    value: PATIENT_SHORT_FORM
                },
                certificateForm: {
                    text: 'Orvosi igazolás',
                    value: CERTIFICATE_FORM
                }
            };
        }

        return {
            covidConfirm: {
                text: 'Covid teszt',
                value: COVID_FORM
            }
        }
    }

    const onAddNewChartClick = () => {
        swal({
            title: 'Milyen típusú chartot szeretne létrehozni?',
            buttons: getNewChartOptions(),
        }).then((chosenFormType: any) => {
            if (chosenFormType) {
                const routeParamsOfNewChart = initNewChart(chosenFormType, createNewChart);
                goToEditPage(routeParamsOfNewChart);
            }
        });
    };

    const getChartFilteredByType = (charts: ChartInterface[]): ChartInterface[] => {
        if (props.match.params.chartListType === routes.homeCovid.substr(1)) {
            return charts.filter((chart: ChartInterface) => chart.forms[0].type === COVID_FORM);
        }

        return charts.filter((chart: ChartInterface) => chart.forms[0].type !== COVID_FORM);
    };

    const listItems = () => {
        if (isChartsLoading) {
            return <Spinner/>;
        }

        if (!charts?.length || !getChartFilteredByType(charts).length) {
            return <h2>Nincsenek kitöltöt űrlapok</h2>;
        }

        return getChartFilteredByType(charts)
            .filter((chart: ChartInterface) => +chart.event_id === +event.id)
            .sort((prev: ChartInterface, next: ChartInterface) => prev.created_date > next.created_date ? -1 : 1)
            .map((chart: ChartInterface) => chart.filtered ? null :
                <IonItem lines="none" key={chart.hash}>
                    <Content onClickHandlers={onClickHandlers}
                             showExaminationList={showExaminationList}
                             {...chart}
                    />
                </IonItem>
            );
    };

    return (
        <>
            <IonList className={classes['ion-list']}>
                <h2>{getChartListTitle(match.params.chartListType)}</h2>
                {listItems()}
            </IonList>

            <Guard roles={[role.doctor]}>
                <IonFab vertical="bottom" horizontal="end" className={classes['add-button']}>
                    <IonFabButton color="success" onClick={onAddNewChartClick}>
                        <IonIcon icon={add}/>
                    </IonFabButton>
                </IonFab>
            </Guard>
        </>
    );
};

export default ChartList;
