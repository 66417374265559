import {
    dynamicInputGroupsNumOfTypesInterface,
    EventInterface, FormDataInterface,
    FormInterface,
    FormOptionsInterface,
    InputFieldInterface
} from "../../store/declarations";
import {loadSavedPhoto, SavePicture} from "./photo";
import {isBase64Image, keys} from "./common";
import {CameraPhoto} from "@capacitor/core";
import axios from "axios";
import * as apiEndpoints from "../consants/apiEndpoints";
import {ToastState} from "../../store/states";
import {dynamicGroups, dynamicInputGroupIdRelations} from "../../store/static/inputFields/dynamicInputGroups";
import swal from "sweetalert";

export const getFieldValue = (fieldName: string, fields: InputFieldInterface[]) => {
    const fieldValue = fields.find((inputField) => {
        return inputField.name === fieldName;
    });

    return fieldValue ? fieldValue.value : '';
};

export const populateDynamicInputGroups = (formData: FormDataInterface, dynamicInputGroupsNumOfTypes: dynamicInputGroupsNumOfTypesInterface, startIndex: number) => {
    let formValuesArr = Object.entries(formData);
    Object.keys(dynamicInputGroupsNumOfTypes).forEach((inputType: string) => {
        const controllerElement = Object.keys(formData).find(key => formData[key].elementConfig?.inputGroupTemplate === inputType);
        if (!controllerElement) {
            return;
        }

        let generatedInputs = [];
        for (let i = 0; i < dynamicInputGroupsNumOfTypes[inputType]; i++) {
            generatedInputs.push(...dynamicGroups[inputType].templates.row.inputRowGenerator(startIndex + i));
        }
        const generatedElementNames = [...Array.from(new Set(generatedInputs.map(input => input[1].elementConfig.row)))];
        formData[controllerElement].elementConfig.displayedRows = [...formData[controllerElement].elementConfig.displayedRows!, ...generatedElementNames];
        formValuesArr.splice(Object.keys(formData).indexOf(controllerElement) - 1, 0, ...generatedInputs);

        formData = Object.fromEntries(formValuesArr);
    });

    return formData;
}

export const getDynamicInputTypesOfCurrentForm = (formData: FormDataInterface) => {
    let dynamicInputGroupsTypes: dynamicInputGroupsNumOfTypesInterface = {};
    Object.keys(formData).forEach(key => {
        if (formData[key].elementConfig?.inputGroupTemplate) {
            dynamicInputGroupsTypes[formData[key].elementConfig?.inputGroupTemplate] = 1
        }
    });

    return dynamicInputGroupsTypes;
}

export const initFormValues = async (form: FormInterface, formValues: InputFieldInterface[], event: EventInterface | null = null, fileSystem: any) => {
    console.log('[functions/form.ts] initFormValues');
    uploadEventSpecificFields(form, event);
    const {readFile, writeFile} = fileSystem;

    let dynamicInputGroupsNumOfTypes: dynamicInputGroupsNumOfTypesInterface = {};

    for (let formValue of formValues) {
        // eslint-disable-next-line no-loop-func
        dynamicInputGroupIdRelations.forEach(rel => {
            const regex = new RegExp('^' + rel.name + '\\d+$', 'gm');

            if (formValue.name.match(regex)) {
                let newNumOfType = 1;
                const inputNumber = parseInt(formValue.name.match(/\d+$/)![0]);

                if ((dynamicInputGroupsNumOfTypes[rel.inputGroupId] || newNumOfType) < inputNumber) {
                    newNumOfType = inputNumber;
                }

                dynamicInputGroupsNumOfTypes[rel.inputGroupId] = newNumOfType;
            }
        });
    }

    dynamicInputGroupsNumOfTypes = Object.assign(getDynamicInputTypesOfCurrentForm(form.form_data), dynamicInputGroupsNumOfTypes);

    form.form_data = populateDynamicInputGroups(form.form_data, dynamicInputGroupsNumOfTypes, 1);

    for (let formValue of formValues) {
        const {name, value} = formValue;

        if (form.form_data.hasOwnProperty(name)) {
            form.form_data[name].value = value;

            if (form.form_data[name].elementType === 'picture' && value) {
                if (!isBase64Image(value)) {
                    await loadSavedPhoto(value, readFile).then((response) => form.form_data[name].base64Data = response);
                    continue;
                }

                const savedFileImage = await saveBase64Image(form, formValue, writeFile);
                form.form_data[name].base64Data = value;
                form.form_data[name].value = savedFileImage.fileName;
            }
        }
    }

    for (let inputName of keys(form.form_data)) {
        form.form_data[inputName].valid = setValidity(form.form_data[inputName].value, form.form_data[inputName].validation);
    }
};

const uploadEventSpecificFields = (form: FormInterface, event: EventInterface | null) => {
    if (event !== null) {
        const fieldsToSet: any = {};
        fieldsToSet['admission_to'] = [...event.place_of_care, ...event.hospitals];
        fieldsToSet['sports'] = event.sports;
        fieldsToSet['accreditation_type'] = event.accreditation;

        for (let inputName of keys(form.form_data)) {
            if (Object.keys(fieldsToSet).indexOf(inputName + '') > -1) {
                let newOptions: FormOptionsInterface[] = [];
                const fields = typeof fieldsToSet[inputName] === 'string' ? fieldsToSet[inputName].trim().split(',') : fieldsToSet[inputName];

                newOptions = fields.map((option: any) => {
                    const name = typeof option === 'string' ? option : option.name;
                    const value = typeof option === 'string' ? option : option.value;

                    return setOptions(name, value);
                });

                if (inputName === 'admission_to') {
                    form.form_data['admission_to'].elementConfig.options![0].displayName = event.address;
                    form.form_data['admission_to'].elementConfig.options![0].optionValue = event.address;
                }

                let oldOptions = [...form.form_data[inputName].elementConfig.options ?? []];
                oldOptions = oldOptions.concat(newOptions);
                form.form_data[inputName].elementConfig.options = oldOptions;
            }
        }
    }
};

export const setOptions = (name: string, value: string) => {
    return {
        displayName: name,
        optionValue: value
    }
};

export function setValidity(value: any, rules: any) {
    let isValid = true;

    if (!rules || (!rules.required && !value)) {
        return true;
    }

    if (rules.required && typeof value == "string") {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.required && typeof value == "object") {
        isValid = (value && value.length > 0) && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
        isValid = isEmail(value) && isValid;
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }

    if (typeof rules.minValue !== "undefined") {
        isValid = (+value >= rules.minValue) && isValid;
    }

    if (rules.maxValue) {
        isValid = (+value <= rules.maxValue) && isValid;
    }

    if (rules.textEditor) {
        isValid = value !== "<p><br></p>" && isValid;
    }

    return isValid;
}

export const isEmail = (value: string) => {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return pattern.test(value);
};

export const setFormArray = (form: FormInterface) => {
    const formElementsArray: any[] = [];

    if (form && form.form_data) {
        for (let key of keys(form.form_data)) {
            formElementsArray.push({
                id: key,
                config: form.form_data[key]
            });
        }
    }

    return formElementsArray;
};

export const saveBase64Image = async (form: FormInterface, formValue: any, writeFile: any) => {
    const {value} = formValue;
    const photo: CameraPhoto = {
        webPath: value,
        format: 'jpeg'
    };
    return await SavePicture(photo, writeFile);
};

export const getFormOptions = (form: FormInterface, config: any): Promise<any> => {
    return (Promise.all([
        axios.post(apiEndpoints.API_HOSPITAL_GET, {}, config),
        axios.post(apiEndpoints.API_ACCREDITATION_GET, {}, config)
    ]).catch((e) => {
        const {showToast} = ToastState();
        console.error('[Events/Edit/Edit.ts] API_EVENT_SHOW - OPTIONS', e);
        showToast('Az adatok lekérése a szerverről sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.')
    }))
};

export const AppendFormWithOptions = async (form: FormInterface, config: any) => {
    const formOptions: any = await getFormOptions({...form}, config);
    const optionsToPopulate = ['hospital', 'accreditation'];
    let getOptionsFailed = false;

    formOptions.forEach((response: any, index: number) => {
        const {payload} = response.data;
        let inputElem: any = [];
        payload.forEach((data: any) => {
            inputElem.push({
                displayName: data.name,
                optionValue: data.value
            });
        });
        form.form_data[optionsToPopulate[index]].elementConfig.options = [...inputElem];
    });

    if (getOptionsFailed) {
        return false;
    }

    return form;
};

export const getEventInputData = (form_data: any): any => {
    let placeOfCare: any = [];

    const regex = new RegExp('place_of_care_\\d+$', 'gm');
    Object.keys(form_data).forEach(inputName => {
        if (inputName.match(regex)) {
            placeOfCare.push(form_data[inputName].value);
        }
    });

    return {
        event: {
            name: form_data.name.value,
            password: form_data.password.value,
            end: form_data.end.value,
            start: form_data.start.value,
            address: form_data.address.value,
            emails: form_data.emails.value,
            city: form_data.city.value,
            sports: form_data.sports.value.join(','),
            name_en: form_data.name_en.value,
            covid_text: form_data.covid_text.value,
            insurance_text: form_data.insurance_text.value,
            insurance_text_en: form_data.insurance_text_en.value
        },
        logo: {
            base64: form_data.logo.base64Data,
            name: form_data.logo.value
        },
        hospitals: form_data.hospital.value,
        accreditation: form_data.accreditation.value,
        place_of_care: placeOfCare
    }
};

export const isEndDateGraterThenBeginDate = (form_data: any) => {
    if (!form_data.start && !form_data.end) {
        return false;
    }
    return form_data.start.value > form_data.end.value;
};

export const setDisplayOfDynamicInputFieldsAndControllers = (newForm: FormInterface) => {
    let buttonsToHide: string[] = [];
    Object.keys(newForm.form_data).forEach(input => {
        const currentConfig = newForm.form_data[input].elementConfig;
        if (newForm.form_data[input].value && currentConfig && currentConfig.button) {
            if (buttonsToHide.indexOf(currentConfig.button) < 0) {
                buttonsToHide.push(currentConfig.button);
            }

            if (newForm.form_data['delete_' + currentConfig.row] && buttonsToHide.indexOf('delete_' + currentConfig.row) < 0) {
                buttonsToHide.push('delete_' + currentConfig.row);
            }

            const rowContainerButtonConfig = newForm.form_data[currentConfig.button].elementConfig;
            if (rowContainerButtonConfig.displayedRows && currentConfig.row && currentConfig.button && rowContainerButtonConfig.displayedRows.indexOf(currentConfig.row) < 0) {
                rowContainerButtonConfig.displayedRows.push(currentConfig.row);
            }
        }
    });

    if (newForm.isLocked) {
        buttonsToHide.forEach(button => {
            if (!newForm.form_data[button].elementConfig.showIfAllRowsDisplayed) {
                newForm.form_data[button].elementConfig.hide = true;
            }
        });
    }
};

export const checkForVisibilityChangeOfDynamicInputs = (activeForm: FormInterface, inputIdentifier: string) => {
    const activeRowID = activeForm.form_data[inputIdentifier].elementConfig.row;
    const buttonShouldBeHidden = Object.keys(activeForm.form_data).filter(input => activeForm.form_data[input].elementConfig.row === activeRowID)
        .some(input => activeForm.form_data[input].value);
    const buttonIDForCurrentRow = 'delete_' + activeForm.form_data[inputIdentifier].elementConfig.row;
    const button = activeForm.form_data[buttonIDForCurrentRow];

    if (button && buttonShouldBeHidden && !button.elementConfig.hide) {
        return {
            newDisplayValue: true,
            buttonToToggle: buttonIDForCurrentRow
        };
    } else if (button && !buttonShouldBeHidden && button.elementConfig.hide) {
        return {
            newDisplayValue: false,
            buttonToToggle: buttonIDForCurrentRow
        };
    }

    return false;
};

export const changeFormElement = (updatedFormElement: any, value: any) => {
    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = setValidity(updatedFormElement.value, updatedFormElement.validation);
};

export const handleDynamicInputs = (inputIdentifier: any, form: FormInterface) => {
    if (form.form_data[inputIdentifier].elementConfig.row && form.form_data[inputIdentifier].elementType === 'input') {
        const visibilityUpdateConfig = checkForVisibilityChangeOfDynamicInputs(form, inputIdentifier);

        if (visibilityUpdateConfig) {
            form.form_data[visibilityUpdateConfig.buttonToToggle].elementConfig.hide = visibilityUpdateConfig.newDisplayValue;
        }
    }
};

export const deleteInputValues = ({row}: any, {form_data}: FormInterface) => {
    for (const key in form_data) {
        if (form_data.hasOwnProperty(key) && form_data[key].elementConfig.row && form_data[key].elementConfig.row === row) {
            form_data[key].value = '';
        }
    }
};

export const onRowDeleteButtonClick = (event: any, deleteButtonIdentifier: string, callback: any) => {
    swal({
        title: 'Biztosan törli?',
        buttons: {
            confirm: {
                text: 'Igen',
                value: true
            },
            cancel: {
                text: 'Mégse',
                value: false,
                visible: true,
            }
        },
    }).then((val: any) => {
        if (val) {
            callback(deleteButtonIdentifier);
        }
    });
};
